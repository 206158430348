import { SYSTEM_ERROR } from "../../src/config/CONSTANT";
import axios from 'axios'
import {GET_USER_PROFILE,GET_ALL_USER} from "../../src/services/CONSTANTS";


/**
 * Function to fetch all the users.
 */
//  export const getAllTrips = () => {
  
    
//     return new Promise((resolve, reject) => {
//       try {
//         // do an SDK, DB call or API endpoint axios call here and return the promise.
//         axios.get(GET_User_BY_ID ())
//         .then((res) => {
        
//           console.log(res , 'res');
  
//           console.log("get User by id > axios res=", res);
//           resolve(res.data);
       
//         })
//         .catch((err) => {
//           console.log("get User by id > axios err=", err);
//           reject("Error in get User by id axios!");
//         });
//       } catch (error) {
//         console.error("in UsersServices > get User by id, Err===", error);
//         reject(SYSTEM_ERROR);
//       }
//     });
//   };

/**
 * Function to fetch the details of the user based on userId.
 * @param {string} userid of the user.
 * early dev example passing Skeleton(static object) as API response.
 */
// export const getUserDetails = (userid) => {
//   console.log("userServices > getUserDetails called...");
//   return new Promise((resolve, reject) => {
//     try {
//       // do an SDK, DB call or API endpoint axios call here and return the promise.
//       axios
//       .get(GET_USER_DETAILS(userid))
//       .then((res) => {
//         console.log("getUserDetails > axios res=", res.data);
//         resolve(res.data);
//       })
//       .catch((err) => {
//         console.log("getUserDetails > axios err=", err);
//         reject("Error in getUserDetails axios!");
//       });
//     } catch (error) {
//       console.error("in userServices > getUserDetails, Err===", error);
//       reject(SYSTEM_ERROR);
//     }
//   });
// };

// export const getAllUser = () => {
//   console.log("userServices > getUserDetails called...");
//   return new Promise((resolve, reject) => {
//     try {
//       // do an SDK, DB call or API endpoint axios call here and return the promise.
//       axios
//       .get(GET_ALL_USER())
//       .then((res) => {
//         console.log("getUserDetails > axios res=", res);
//         resolve(res.data);
//       })
//       .catch((err) => {
//         console.log("getUserDetails > axios err=", err);
//         reject("Error in getUserDetails axios!");
//       });
//     } catch (error) {
//       console.error("in userServices > getUserDetails, Err===", error);
//       reject(SYSTEM_ERROR);
//     }
//   });
// };
export const getProfileUser = (id) => {
  console.log("userServices > getProfileUser called...");
  return new Promise((resolve, reject) => {
    try {
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios
      .get(GET_USER_PROFILE(id))
      .then((res) => {
        console.log("getUserDetails > axios res=", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getUserDetails > axios err=", err);
        reject("Error in getUserDetails axios!");
      });
    } catch (error) {
      console.error("in userServices > getUserDetails, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getAllUsers = () => {
  
    
  return new Promise((resolve, reject) => {
    try {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
   
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios.get(GET_ALL_USER(),{headers:headers})
      .then((res) => {
      
        console.log(res.data.data.data, 'res');

        // console.log("getAllMarshalls > axios res=", res);
        resolve(res.data);
     
      })
      .catch((err) => {
        // console.log("getAllMarshalls > axios err=", err);
        reject("Error in getAllMarshalls axios!");
      });
    } catch (error) {
      // console.error("in marshallsServices > getAllMarshalls, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
