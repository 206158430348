import { useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./styles/theme";
import Topbar from "../src/pages/scenes/global/Topbar";
import Sidebar from "../src/pages/scenes/global/Sidebar";
import RouterConfig from "./navigation/RouterConfig";
import { LicenseInfo } from '@mui/x-license-pro';
import Login from '../src/pages/scenes/auth/Login';

LicenseInfo.setLicenseKey('YOUR_LICENSE_KEY');
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  console.log(isSidebar)
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Login /> */}
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}

            <RouterConfig >

      
            </RouterConfig>


          </main>

        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
