// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../config/CONSTANT";
import axios from 'axios'
import { GET_ALL_Info } from "./CONSTANTS";

/**
 * Function to fetch all the users.
 */
export const getAllinfosite = () => {


  return new Promise((resolve, reject) => {
    try {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      // do an SDK, DB call or API endpoint axios call here and return the promise.
      axios.get(GET_ALL_Info(),{ headers: headers })
        .then((res) => {

          console.log(res, 'res');

          //   console.log("getAllMarshalls > axios res=", res);
          resolve(res.data);

        })
        .catch((err) => {
          //   console.log("getAllMarshalls > axios err=", err);
          reject("Error in getAllLevel axios!");
        });
    } catch (error) {
      console.error("in levelServices > getAllLevel, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};


  //update level function



