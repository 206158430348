import React, { useState, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getFundtype } from "../../../services";
import { getAllfund } from "../../../services";
import { useForm, Form } from "./useForm1";
import Cookies from "js-cookie";
import Controls from "../global/controls/Controls";
import { getAllLevel } from "../../../services";
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import { MenuProps, useStyles, fullname } from "./utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));
export default function Createnewuser() {
  const navigate = useNavigate();
  const classes = useStyles();
  // const [fund, setfund] = useState([]);
  const [Country_code, setCountry_code] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);
  const [level, setlevel] = useState([]);
  const [userlevel, setUserlevel] = useState([]);
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/get_country_codes`, { headers: headers })
      .then((res) => {
        setCountry_code(res.data.data.map(e => e.iso));

      })

  }, []);
  useEffect(() => {

    getAllLevel()
      .then((res) => {
        console.log(res.data);
        setlevel(res.data);
        
      })
      .catch((err) => {
        console.log("axios err=", err);
        setlevel([]);
        
      });
    
      return () => {
        console.log("axios cleanup.");
      };
    }, []);

  const initialFValues = {
    full_name: "",
    username: "",
    phone_number: "",
    // country_code: "",
    email: "",
    // level_id: "",
    points: "",
    image: "",
    whatsapp_link: "",
    facebook_link: "",
    instgram_link: "",
    password: "",
    points: " ",
  };
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

    if ("full_name" in fieldValues) {

      if (fieldValues.full_name == '') {
        temp.full_name = "This is field is required";

      } else {

        temp.full_name = "";
      }

    }
    if ("username" in fieldValues) {

      if (fieldValues.username == '') {
        temp.username = "This is field is required";

      } else {

        temp.username = "";
      }

    }
    if ("phone_number" in fieldValues) {

      if (fieldValues.phone_number == '') {
        temp.phone_number = "This is field is required";

      } else {

        temp.phone_number = "";
      }

    }
    // if ("country_code" in fieldValues){

    //   if(fieldValues.country_code == ''){
    //     temp.country_code = "This is field is required";

    //   }else{

    //     temp.country_code = "";
    //   }

    // }
    if ("email" in fieldValues) {

      if (fieldValues.email == '') {
        temp.email = "This is field is required";

      } else {

        temp.email = "";
      }


    }
    // if ("level_id" in fieldValues){

    //   if(fieldValues.level_id == ''){
    //     temp.level_id = "This is field is required";
     
    //   }else{
        
    //     temp.level_id = "";
    //   }
    
    
    // }
    if ("points" in fieldValues){

      if (fieldValues.points == '') {
        temp.points = "This is field is required";

      } else {

        temp.points = "";
      }


    }
    if ("image" in fieldValues) {

      if (fieldValues.image == '') {
        temp.image = "This is field is required";

      } else {

        temp.image = "";
      }


    }
    if ("whatsapp_link" in fieldValues) {

      if (fieldValues.whatsapp_link == '') {
        temp.whatsapp_link = "This is field is required";

      } else {

        temp.whatsapp_link = "";
      }


    }
    if ("facebook_link" in fieldValues) {

      if (fieldValues.facebook_link == '') {
        temp.facebook_link = "This is field is required";

      } else {

        temp.facebook_link = "";
      }


    }
    if ("instgram_link" in fieldValues) {

      if (fieldValues.instgram_link == '') {
        temp.instgram_link = "This is field is required";

      } else {

        temp.instgram_link = "";
      }


    }
    if ("password" in fieldValues) {

      if (fieldValues.password == '') {
        temp.password = "This is field is required";

      } else {

        temp.password = "";
      }


    }


    setErrors({
      ...temp,
    });
    if (fieldValues == loginInput)
      return Object.values(temp).every((x) => x == "");
  };

  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handleInputfile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    // if (validate()) {
        e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("full_name", loginInput.full_name);
        bodyFormData.append("username", loginInput.username);
        bodyFormData.append("phone_number", loginInput.phone_number);
        bodyFormData.append("country_code", Newcountrycode);
        bodyFormData.append("email", loginInput.email);
        bodyFormData.append("level_id", userlevel);
        bodyFormData.append("points", loginInput.points);
        bodyFormData.append("image", e.target.image.files[0]);
        bodyFormData.append("whatsapp_link", loginInput.whatsapp_link);
        bodyFormData.append("facebook_link", loginInput.facebook_link);
        bodyFormData.append("instgram_link", loginInput.instgram_link);
        bodyFormData.append("password", loginInput.password);
        bodyFormData.append("points", loginInput.points);
       
        axios
          .post(`https://ijk.expentech.com/api/admin_panel/users`, bodyFormData,{headers:headers})
          .then((res) => {
            console.log(res.status);
            if (res.status  === 200) {
              sweetalert("Added successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/users");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              setLogin({ ...loginInput, error_list: res.data.error });
            }
          });
      };
    // }
      
 // ==============================================
    const [selectedImage5, setSelectedImage5] = useState();
 
     // This function will be triggered when the file field change
     const imageChange = (e) => {
         if (e.target.files && e.target.files.length > 0) {
           setSelectedImage5(e.target.files[0]);
         }
     };
 
 
  // This function will be triggered when the "Remove This Image" button is clicked
     const removeSelectedImage = () => {
           setSelectedImage5();
             
       };
    const styles = {
       preview: {
         marginTop: 50,
         display: "flex",
         flexDirection: "column",
       },
       image: { maxWidth: "100%", maxHeight: 320 },
       delete: {
         cursor: "pointer",
         padding: 15,
         background: "red",
         color: "white",
         border: "none",
       },
     };
 // ==============================================  
 

  return (
    <div className="main-div">
      <h3 className="titlepage">Add User</h3>

      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="Full Name"
              className="form-control"
              label="Full Name"
              name="full_name"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.full_name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="User Name"
              className="form-control"
              label="User Name"
              name="username"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.username}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="phone number"
              className="form-control"
              label="phone number"
              name="phone_number"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.phone_number}
            />
          </Grid>
          <Grid item md={6} xs={12}>
        
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="country_code"
                  onChange={(e) => setNewcountrycode(e.target.value)}>
                  <option selected disabled>Chooes one option</option>
                  {Country_code.map((opts,i) => (
                    <option key={i} value={opts}>
                      {opts}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">country_code</label>
              </div>
            </Grid>
        
       
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="email"
              className="form-control"
              label="email"
              name="email"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.email}
            />
          </Grid>
          <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="level"
                  onChange={(e) => setUserlevel(e.target.value)}
                  >
                  <option selected disabled>Chooes one option</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts.id}>
                      {opts.name}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">Level</label>
              </div>
       

          </Grid>
          <Grid item md={12} xs={12}>
            <Controls.Input
              placeholder="points"
              className="form-control"
              label="points"
              name="points"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.points}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Controls.Input
              placeholder=""
              className="form-control"
              label=""
              name="image"
              fullWidth
              required
              type="file"
              onChange={imageChange}
              error={errors.image}
            />
            
          </Grid>
          <Grid item md={12} xs={12}>
           {selectedImage5 && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage5)}
              style={styles.image}
              alt="Thumb"
            />
          {/* {URL.createObjectURL(selectedImage)} */}
{/*             <button onClick={removeSelectedImage} style={styles.delete}>
              Remove This Image
            </button> */}
          </div>
        )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="whatsapp_link"
              className="form-control"
              label="whatsapp_link"
              name="whatsapp_link"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.whatsapp_link}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="facebook_link"
              className="form-control"
              label="facebook_link"
              name="facebook_link"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.facebook_link}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="instgram_link"
              className="form-control"
              label="instgram_link"
              name="instgram_link"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.instgram_link}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="password"
              className="form-control"
              label="password"
              name="password"
              fullWidth
              required
              type="password"
              onChange={handleInputChange}
              error={errors.password}
            />
          </Grid>

        </Grid>

        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
    </div>
  );
}
