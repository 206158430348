import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";
import * as sweetalert from "sweetalert";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { useAuth } from "../../../navigation/Auth/auth";


export function useForm(initialFValues, validateOnChange = false, validate) {

    const navigate = useNavigate();
    // const auth = useAuth();
    const location = useLocation();
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const redirectPath = location.state?.path || "/dashboard";
    const [loginInput, setLogin] = useState({
        name: "",
        start_date: "",
        location: "",
        capacity: "",
        minimum_pnumber: "",
        trip_place: "",
        longitude: "",
        latitude: "",
        description: "",
        level_id: "",
        end_time: "",
        frequency: "",
        is_camped:" ",
        image:" ",
        city:" ",
        area:" ",
      });
    const handleInputChange = e => {
        // console.log('12123');
        const { name, value } = e.target
        // console.log(value);
        setLogin({ ...loginInput, [name]: value });
        console.log(loginInput);
        // if (validateOnChange)
        // validate({ [e.target.name]:  e.target.value })
            
    }
    const handlefile = e => {
        // const { name, value } = e.target
        setLogin({ ...loginInput, [e.target.name]:  e.target.files[0] });
        // console.log(loginInput);
        // if (validateOnChange)
        //     validate({ [e.target.name]:  e.target.files[0] })
            
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }



    const addForm = () => {
        if (validate()) {
           
          }
        
    }

    return {
        loginInput,
        setLogin,
        errors,
        setErrors,
        handleInputChange,
        handlefile,
        addForm,
        resetForm

    }
  
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

