// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../../src/config/CONSTANT";
import axios from 'axios'
import { GET_ALL_Marshalls} from "../../src/services/CONSTANTS";

/**
 * Function to fetch all the users.
 */
 export const getAllMarshalls = () => {
  
    
    return new Promise((resolve, reject) => {
      try {
        // do an SDK, DB call or API endpoint axios call here and return the promise.
        let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
          headers = { Authorization: localStorage.getItem("SavedToken") };
        }
        axios.get(GET_ALL_Marshalls(), { headers: headers })
        .then((res) => {
        
          console.log(res , 'res');
  
          console.log("getAllMarshalls > axios res=", res);
          resolve(res.data);
       
        })
        .catch((err) => {
          console.log("getAllMarshalls > axios err=", err);
          reject("Error in getAllMarshalls axios!");
        });
      } catch (error) {
        console.error("in marshallsServices > getAllMarshalls, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };




