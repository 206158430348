import { React, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  useTheme,
} from "@material-ui/core";
import { AccountCircle, LockRounded } from "@material-ui/icons";
import axios from "axios";
import { Typography } from "@mui/material";
import { tokens } from "../../../styles/theme";
import * as sweetalert from "sweetalert";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const emaillogin = localStorage.getItem("email");
  const [resetpassword, setResetPassword] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const handeleInput = (e) => {
    e.persist();
    setResetPassword({ ...resetpassword, [e.target.name]: e.target.value });
  };
  // useEffect(() => {
  //   emaillogin =  localStorage.getItem("email") ;
  // },[])
  const loginSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    
    }
   
    const data = {
      email: emaillogin,
      password: resetpassword.password,
      password_confirmation: resetpassword.password_confirmation,
    };

    axios
      .post(`https://ijk.expentech.com/api/admin_panel/reset_password`,data,{headers:headers},{
        withCredentials: true,
      })
      .then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          localStorage.removeItem('SavedToken');
          localStorage.removeItem('email');
          sweetalert("Password has been successfully changed");
          navigate("/");
        } else {
          sweetalert("Error some thing", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      });
  };

  return (
    <div>
      <form className="form-group" onSubmit={loginSubmit}>
      
        <Grid container style={{ minHeight: "100vh" }}>
          
          <Grid 
            container
            item
            alignItems="center"
            direction="column"
            justify="center"
            xs={12}
            sm={12}
            style={{ padding: 10 }}
          >
            <div>
            
              <h2 className="text-danger">Reset Password</h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
  <Grid  className="testt">

              <TextField
                className="form-control"
                variant="outlined"
                fullWidth
                label="password"
                required
                type="password"
                name="password"
                onChange={handeleInput}
                value={resetpassword.password}
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "1rem" },

                  "& .MuiOutlinedInput-root": { fontSize: "1rem" },
                }}
              />
  </Grid>
  <Grid  className="testt">
              <TextField
                className="form-control"
                variant="outlined"
                fullWidth
                label="password_confirmation"
                required
                type="password"
                name="password_confirmation"
                onChange={handeleInput}
                value={resetpassword.password_confirmation}
                sx={{
                  "& .MuiInputLabel-root": { fontSize: "1rem" },

                  "& .MuiOutlinedInput-root": { fontSize: "1rem" ,margin:20,  },
                }}
              />
</Grid>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#CD2D49",
                  fontSize: "18px",
                  color: "#fff",
                  margin: "20px 0",
                }}
                variant="contained"
              >
                 Submit
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
