import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect, useState } from "react";
ChartJS.register(ArcElement, Tooltip, Legend);

const options = {

  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Horizontal Bar Chart",
    // },
  },
};
const PieChart2 = () => {
  const [data, setData] = useState({
    labels: [
      
    ],
    datasets: [
      {
        label: "Coun Of Trips",
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(100, 200, 100, 0.2)',
          'rgba(255, 159, 64, 0.2)',
         
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(100, 200, 100, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          
        ],
        borderWidth: 1,
        width:10,
        height:10
      },
      
    ],
  });
  useEffect(() => {
    const fetchData = async () => {
      //  const url = 'https://jsonplaceholder.typicode.com/comments'
      const labelSet = [];
      const dataSet1 = [];
      const dataSet2 = [];
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      await fetch(`https://ijk.expentech.com/api/admin_panel/dashboard`, {
        headers: headers,
      })
        .then((data) => {
          console.log("Api data", data);
          const res = data.json();
          return res;
        })
        .then((res) => {
         
          for (const val of res.data.levels) {
            dataSet1.push(val);
          }
          for (const val of res.data.tripNotEndByLevel) {
            dataSet2.push(val);
          }
          
          setData({
            labels: dataSet1,
              
            
            datasets: [
              {
                label: "Coun Of Trips",
                data: dataSet2,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
                
              },
              // {
              //   label: "Dataset ID2",
              //   data: dataSet2,
              //   borderColor: "rgb(53, 162, 235)",
              //   backgroundColor: "rgba(53, 235, 0.5)",
              // },
            ],
          });
          console.log("arrData", dataSet1, dataSet2);
        })
        .catch((e) => {
          console.log("error", e);
        });
    };

    fetchData();
  }, []);
  return (
    <div style={{  height: "30%" , marginTop: '-70px'}} >
     
     <Pie data={data} options={options}/>
    </div>
  );
};


export default PieChart2;
