import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";
import * as sweetalert from "sweetalert";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useLocation, Link } from "react-router-dom";



export function useForm(initialFValues, validateOnChange = false, validate) {

    // const navigate = useNavigate();
  
    // const location = useLocation();
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    // const redirectPath = location.state?.path || "/dashboard";
    const [loginInput, setLogin] = useState({
        full_name: "",
        username: "",
        phone_number:"",
        country_code:"",
        points:"",
        email:"",
        whatsapp_link:"",
        facebook_link:"",
        instgram_link:"",
        car_model:"",
        car_number:"",
        car_manufacturing_year:"",
        password:"",
        image:"",
        car_image:"",
      });
    const handleInputChange = e => {
        // console.log('12123');
        const { name, value } = e.target
        // console.log(e.target);
        setLogin({ ...loginInput, [name]: value });
        console.log(loginInput);
        if (validateOnChange)
            validate({ [name]: value })
            
    }

    const handlefile = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.files[0] });

        // const { name, value } = e.target.files[0]
        // // console.log(e.target);
        // setLogin({ ...loginInput, [name]: value });
        // console.log(loginInput);
        // if (validateOnChange)
        //     validate({ [name]: value })
        // e.persist();
        // setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      };

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }



    const addForm = () => {
        if (validate()) {
           
          }
        
    }

    return {
        loginInput,
        setLogin,
        errors,
        setErrors,
        handleInputChange,
        handlefile,
        addForm,
        resetForm
    }
  
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

