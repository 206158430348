import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../styles/theme";
import axios from "axios";
import * as sweetalert from "sweetalert";
import Header from "../../../components/Header";
import { getAllTrips } from "../../../services";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import ConfirmDialog from "../global/ConfirmDialog";
import Tooltip from "@mui/material/Tooltip";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { randomId } from "@mui/x-data-grid-generator";

const initialRows = [
  {
    id: 1,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 2,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 3,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 4,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 5,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 6,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 7,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 8,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 9,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 10,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 11,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
];
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        Trip: "",
        Startdate: "",
        image: "",
        city: "",
        Area: "",
        Comping: "",
        Level: "",
        Marshall: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <>
      <GridToolbarContainer>
        <Box className="div-btn-cancel">
          <Button
            component={Link}
            variant="contained"
            className="btn-view"
            color="secondary"
            to="/Add-new-trip"
          >
            <AddIcon style={{ color: "white", fontSize: "15px" }} />
            Add Trip
          </Button>
        </Box>
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}
EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Trips = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [levels, setlevels] = useState([]);
  const [levelname, setLevelname] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState();
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState(initialRows);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (id) => () => {
    setOpen(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, {
        headers: headers,
      })
      .then((res) => {
        
        setDataAnnouncement(res.data.data.image);
        console.log(dataAnnouncement);
        setIsLoading(false);
      });
    // setRows(rows.filter((row) => row.id !== id))
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    setIsLoading(true);

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/all_trips`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data.data);
        setUsers(res.data.data);
        setIsLoading(false);
        setlevels(res.data.data.map((e) => e.level));
        setLevelname(levels.map((e) => e.name));
        console.log(levelname);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setUsers([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  function handleDeleteClick (id)  {
    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    // let headers = {};
    // if (localStorage.getItem("SavedToken") !== null) {
    //   headers = { Authorization: localStorage.getItem("SavedToken") };
    // }

    // axios
    //   .delete(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, {
    //     headers: headers,
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       sweetalert("deleted successfully", {
    //         className: "alert-div",
    //         button: {
    //           text: "ok",
    //         },
    //       });
    //       setUsers(users.filter((row) => row.id !== id));
    //     } else {
    //       sweetalert("not found user id", {
    //         className: "alert-div",
    //         button: {
    //           text: "ok",
    //         },
    //       });
    //     }
    //   })
    //   .catch((err) => {});
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleViewClick = (id) => () => {
    navigate(`/view-trip/${id}`, { state: { id: id } });
  };
  const handleuserClick = (id) => () => {
    navigate(`/trip_users/${id}`, { state: { id: id } });
  };
  const columns = [
    {
      field: "name",
      headerName: "Trip",
      cellClassName: "name-column--cell",
      flex:1,
      editable: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex:1,
      editable: true,
    },

    {
      field: "location",
      headerName: "City",
      flex:1,
      editable: true,
    },
    {
      field: "trip_place",
      headerName: "Area",
      flex:1,
      editable: true,
    },
    {
      field: "is_camped",
      headerName: "Camping",
      flex:1,
      editable: true,
      valueGetter: (params) => {
        if (params.row.is_camped == 1) {
          return "Yes";
        } else {
          return "no";
        }
      },
    },
    {
      field: { levelname },
      headerName: "Level",
      flex:1,
      editable: true,
      valueGetter: (params) => {
        return params.row.level.name;
      },
    },
    {
      field: "",
      headerName: "Marshall",
      flex:1,
      editable: true,
      valueGetter: (params) => {
        return params.row.marshall.user.username;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex:2,
      cellClassName: "actions",
      getActions: ( id ) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              navigate(`/update-trip/${id.row.id}`, { state: { id: id } });
            }}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<VisibilityRoundedIcon />}
            label="View"
            onClick={handleViewClick(id.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<GroupsIcon />}
            label="view"
            onClick={handleuserClick(id.row.id)}
            color="inherit"
          />,
          
          <GridActionsCellItem
          icon={
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          color="inherit"
          onClick={
            
             
                handleDeleteClick(id.row.id)
              }
           
        />,

        // <ConfirmDialog
        //   confirmDialog={confirmDialog}
        //   setConfirmDialog={setConfirmDialog}
        // />,
          <GridActionsCellItem
            icon={
              <Tooltip title="show Image">
                <ImageIcon />
              </Tooltip>
            }
            label="view"
            onClick={handleClickOpen(id.row.id)}
            color="inherit"
          />,
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Personal Image</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <img
                  height="100"
                  width="200"
                  alt="med1"
                  src={"https://ijk.expentech.com/storage/" + dataAnnouncement}
                />
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>,
       
         
         
          

         
        ];
      },
    },
  ];
  return (
    <Box m="2px 5px 0px 5px">
      <Header title="Trips" />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.grey[600]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGridPro
          rows={users}
          columns={columns}
          editMode="row"
          pageSize={12}
          pagination
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  );
};

export default Trips;
