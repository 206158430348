// All user related database operations can be defined here.

import { SYSTEM_ERROR } from "../../src/config/CONSTANT";
import axios from 'axios'
import { GET_ALL_TRIPS} from "../../src/services/CONSTANTS";

/**
 * Function to fetch all the users.
 */
 export const getAllTrips = () => {
  
    
    return new Promise((resolve, reject) => {
      try {
        // do an SDK, DB call or API endpoint axios call here and return the promise.
        axios.get(GET_ALL_TRIPS())
        .then((res) => {
        
          console.log(res , 'res');
  
          // console.log("getAlltrips > axios res=", res);
          resolve(res.data);
       
        })
        .catch((err) => {
          // console.log("getAlltrips > axios err=", err);
          reject("Error in getAllTrips axios!");
        });
      } catch (error) {
        // console.error("in tripServices > getAllTrips, Err===", error);
        reject(SYSTEM_ERROR);
      }
    });
  };

/**
 * Function to fetch the details of the user based on userId.
 * @param {string} userid of the user.
 * early dev example passing Skeleton(static object) as API response.
 */
// export const getUserDetails = (id) => {
//   console.log("userServices > getUserDetails called...");
//   return new Promise((resolve, reject) => {
//     try {
//       // do an SDK, DB call or API endpoint axios call here and return the promise.
//       axios
//       .get(GET_USER_DETAILS(id))
//       .then((res) => {
//         console.log("getUserDetails > axios res=", res);
//         resolve(res.data);
//       })
//       .catch((err) => {
//         console.log("getUserDetails > axios err=", err);
//         reject("Error in getUserDetails axios!");
//       });
//     } catch (error) {
//       console.error("in userServices > getUserDetails, Err===", error);
//       reject(SYSTEM_ERROR);
//     }
//   });
// };


