import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { Navigate, useHistory, useParams } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useNavigate } from "react-router-dom";
import {

  GridToolbarContainer,

} from "@mui/x-data-grid-pro";
import Button from "@material-ui/core/Button";
import { isGridCellRoot } from '@mui/x-data-grid/utils/domUtils';
export default function ViewTripbyid() {
  const { id } = useParams();
  const [start_date, setstart_date] = useState("");
  const [end_time, setend_time] = useState("");
  const [location, setlocation] = useState("");
  const [capacity, setcapacity] = useState("");
  const [minimum_pnumber, setminimum_pnumber] = useState("");
  const [frequency, setfrequency] = useState("");
  const [trip_place, settrip_place] = useState("");
  const [level, setlevel] = useState("");
  const [description, setdescription] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    // console.log(headers);
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, { headers: headers })
      .then((res) => {
        console.log(res.data);
        setstart_date(res.data.start_date);
        setend_time(res.data.end_time);
        setlocation(res.data.location);
        setcapacity(res.data.capacity);
        setfrequency(res.data.frequency);
        setminimum_pnumber(res.data.minimum_pnumber);
        settrip_place(res.data.trip_place);
        setlevel(res.data.level.name);
        setdescription(res.data.description);


      })
      .catch((err) => {

      });
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleNavigateClick = () => {
    if(location.pathname == `/view-trip/${id}`){
      navigate(`/trips`);
    }else if(location.pathname == `/created_trips/${id}`){
      navigate(`trips`);
    }else{
      navigate(`trips`);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="main-div">
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <div className="userdetails">
            <div className="userdetailsborder">
              <h3>Trip Informations</h3>
            </div>
            <table className="table">
              <tr>
                <th>Start Data</th>
                <td>{start_date}</td>
                <th>End Date</th>
                <td>{end_time}</td>
              </tr>
              <tr>
                <th>Location</th>
                <td>{location}</td>
                <th>Capacity</th>
                <td>{capacity}</td>
              </tr>
              <tr>
                <th>Frequency</th>
                <td>{frequency}</td>
                <th>Minimum Pnumber</th>
                <td>{minimum_pnumber}</td>
              </tr>
              <tr>
                <th>Trip Place</th>
                <td>{trip_place}</td>
                <th>User Count</th>
                <td></td>
              </tr>
              <tr>
                <th>Level</th>
                <td>{level}</td>
                <th>Marshall</th>
                <td></td>
              </tr>
              <tr>
                <th>Description</th>
                <td>{description}</td>
              </tr>
            </table>
          </div>

        </Grid>

       

      </Grid>
    </div>
  )
}

