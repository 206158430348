import React, { useState, useEffect } from "react";
import * as sweetalert from "sweetalert";
import { useNavigate ,useParams} from "react-router-dom";
import axios from "axios";
import { getAllLevel } from "../../../services";
import { useForm, Form } from "./useForm1";
import Controls from "../global/controls/Controls";
import {
  Grid,
  FormControl
} from "@material-ui/core";
export default function UpdateLevelUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const [level, setlevel] = useState([]);
  const [Newlevel, setNewlevel] = useState([]);
  const [Userlevel, setUserlevel] = useState([]);
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
 
      axios
        .get(`https://ijk.expentech.com/api/admin_panel/users/${id}`, { headers: headers })
        .then((res) => {
          setUserlevel(res.data.data.level.name);
         
        })
 
  }, []);

  useEffect(() => {

    getAllLevel()
      .then((res) => {
        console.log(res.data);
        setlevel(res.data);
        
      })
      .catch((err) => {
        console.log("axios err=", err);
        setlevel([]);
        
      });
    
      return () => {
        console.log("axios cleanup.");
      };
    }, []);
  const initialFValues = {
    level_id: "",
  };
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

 
    if ("level_id" in fieldValues){

      if(fieldValues.level_id == ''){
        temp.level_id = "This is field is required";
     
      }else{
        
        temp.level_id = "";
      }
    
    
    }

  
 
   

  


    setErrors({
      ...temp,
    });
    if (fieldValues == loginInput)
      return Object.values(temp).every((x) => x == "");
  };

  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handleInputfile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    // if (validate()) {
    //     e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("level_id", Newlevel);
     
        axios
          .get(`https://ijk.expentech.com/api/admin_panel/update_user_level/${id}?level_id=${Newlevel}`,{headers:headers})
          .then((res) => {
            console.log(res.status);
            if (res.status  === 200) {
              sweetalert("Level modified successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/users");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              setLogin({ ...loginInput, error_list: res.data.error });
            }
          });
      // };
    }
      
   
 

  return (
    <div className="main-div">
      <h3 className="titlepage">Edit User Level </h3>

      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>

            <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="level_id"
                 onChange={
                    (e) => console.log(setNewlevel(e.target.value),Newlevel)
                    // console.log(Newlevel)
                    }
                  >
                   <option value={Userlevel} selected disabled>{Userlevel}</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts.id}>
                      {opts.name}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label"></label>
              </div>
       

          </Grid>
          </Grid>
          
         
        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
    </div>
  );
}
