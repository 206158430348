import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import { mockTransactions } from "../../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../../components/Header";
import LineChart from "../../../components/LineChart";
import Horizontalchart from "../../../components/chart-horizontal";
import PieChart from "../../../components/PieChart1";
import PieChart2 from "../../../components/PieChart2";
import GeographyChart from "../../../components/GeographyChart";
import BarChart from "../../../components/BarChart1";
import StatBox from "../../../components/StatBox";
import ProgressCircle from "../../../components/ProgressCircle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PieChart3 from './../../../components/PieChart3';
const Dashboard = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [topMarshalles, seTopMarshalles] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
          headers = { Authorization: localStorage.getItem("SavedToken") };
        }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/dashboard`, { headers: headers })
      .then((res) => {
        setTopUsers(res.data.data.topUser);
        seTopMarshalles(res.data.data.topMarshall);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setTopUsers([]);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  return (
    <Box m="5px 5px 0px 5px">
      {/* HEADER */}

      <Box display="flex" justifyContent="space-between" alignItems="center">
      <div className="main-div">
        <Header
          title="Trips’ Statistics "
          
        />
        </div>

        <Box>
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.white[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Trips Level
          </Typography>
          <Box height="250px" mt="-20px">
            <Horizontalchart isDashboard={true} />
          </Box>
        </Box>
        
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Top 10 Users
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <Typography color={colors.redAccent[500]}>ID</Typography>
            </Typography>
            <Typography color={colors.redAccent[500]}>Full name</Typography>
            <Typography color={colors.redAccent[500]}>Points</Typography>
          </Box>
          {topUsers.map((transaction, i) => (
            <Box
              // key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.grey[100]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.id}
                </Typography>
              </Box>
              <Box>
                <Typography color={colors.grey[100]}>
                  {transaction.full_name}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.points}</Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn="span 3"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Top 10 Marshalls
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              <Typography color={colors.redAccent[500]}>ID</Typography>
            </Typography>
            <Typography color={colors.redAccent[500]}>Full name</Typography>
            <Typography color={colors.redAccent[500]}>Points</Typography>
          </Box>
          {topMarshalles.map((transaction, i) => (
            <Box
              // key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.grey[100]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.id}
                </Typography>
              </Box>
              <Box>
                <Typography color={colors.grey[100]}>
                  {transaction.full_name}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.points}</Box>
            </Box>
          ))}
        </Box>

        {/* ROW 1 */}

       
        {/* ROW 2 */}

       

        
        {/* ROW 3 */}

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="300"
            sx={{ padding: "30px 30px 0 30px" }}
          >
           Finished Trips Levels
          </Typography>
          <Box height="250px" mt="-20px">
            <PieChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="300"
            sx={{ padding: "30px 30px 0 30px" }}
          >
           Unfinished Trips Levels
          </Typography>
          <Box height="250px" mt="-20px">
            <PieChart2 isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="300"
            sx={{ padding: "30px 30px 0 30px" }}
          >
          Finished and Unfinished Trips Levels
          </Typography>
          <Box height="250px" mt="-20px">
            <PieChart3 isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
