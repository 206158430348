import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../styles/theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { DASHBOARD, MARSHALLS, JOINED_TRIPS,CREATED_TRIPS,TRIPS,MY_TRIPS,USER ,LEVEL,ANNOUNCEMENTS,PROFILE,SITEINFO} 
from "../../../navigation/CONSTANTS";
import axios from "axios";
import * as sweetalert from 'sweetalert';
import { useNavigate } from 'react-router-dom';

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const [selected, setSelected] = useState("Dashboard");
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };



const logout =(e) =>{
  e.preventDefault();
  
  let headers = {};
  if (localStorage.getItem("SavedToken") !== null) {
    headers = { Authorization: localStorage.getItem("SavedToken") };
  }

  axios.get('https://ijk.expentech.com/api/admin_panel/logout',{ headers: headers })
  .then(res=>{
      if(res.data.code === 200){
        localStorage.removeItem('SavedToken');
        localStorage.removeItem('email');
        sweetalert('You are logout successfully');
        navigate('/');
      }
      else{
        sweetalert('some thing error');
      }
  });
}
const locale = localStorage.getItem("SavedToken");
const username=localStorage.getItem("username");


if (locale != null) {
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          // background: `${colors.grey [1000]} !important`,
          // backgroundImage:URL('../../')
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          color:`${colors.redAccent[500]} !important`,
        },
        "& .pro-inner-item button": {
          color:`${colors.redAccent[500]} !important`,
        },
        "& .pro-item-content > p": {
          fontSize: "16px !important",
          fontWeight:'600',
        },
        "& .pro-inner-item:hover": {
          color: "#d84444 !important",
        },
        "& .pro-menu-item.active": {
          color: "#d84444 !important",
        },
        "& .css-ipgleb-MuiButtonBase-root-MuiListItemButton-root":{
            color:`${colors.redAccent[500]} !important`,
          
        },
        "& .setting":{
            fontSize: "16px !important",
            fontWeight:'600',
            padding: "5px 35px 5px 20px !important",

        },
        "& .logout":{
            padding: "5px 35px 5px 30px !important",
            fontSize: "16px !important",
            fontWeight:'600',
            color:`${colors.redAccent[500]} !important`,

        },
        "& .reset":{
            padding: "5px 35px 5px 30px !important",
            fontSize: "16px !important",
            fontWeight:'600',
            color:`${colors.redAccent[500]} !important`,

        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.redAccent[500]}>
                  ADMINIS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`logo.PNG`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.primary[600]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                welcome {username}
                </Typography>
                {/* <Typography variant="h5" color={colors.redAccent[500]}>
                  VP Fancy Admin
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to={DASHBOARD}
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Marshalls Trips"
              to={MARSHALLS}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Trips"
              to="/trips"
              icon={<CommuteOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Created Trips"
              to={CREATED_TRIPS}
              icon={<CommuteOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Joined Trips"
              to={JOINED_TRIPS}
              icon={<CommuteOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Users"
              to="/users"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Levels"
              to="/level"
              icon={<MoveUpOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Announcements"
              to="/announcements"
              icon={<CampaignOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Site Info"
              to="/Siteinfo"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
     
            <Item
              title="Admins Profiles"
              to="/profile"
              icon={<AccountBoxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

       
          <ListItemButton className="btn-sidebare" onClick={handleClick}>
          
                  <SettingsIcon />
              
                <ListItemText
                  className="setting"
                  primary="Setting"
                
                />
                {open ? <ExpandLess className="openChanel" /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                className="collapse-sidebar"
              >
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                  >
                    <ListItemText
                      className="logout"
                      primary="Sign Out"
                      onClick={logout}
                    />
                  </ListItemButton>

                  <ListItemButton
                    component={Link}
                    to="/reset-password"
                    sx={{ pl: 4 }}
                  >
                    <ListItemText
                      className="reset"
                      primary="Reset password"
                    />
                  </ListItemButton>

                </List>
              </Collapse>

          </Box>
        </Menu>
      </ProSidebar>
     
    </Box>
  );
};
};

export default Sidebar;
