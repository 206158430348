import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { Navigate, useHistory, useParams } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Header from '../../../components/Header';
import {

    GridToolbarContainer,

} from "@mui/x-data-grid-pro";
import Button from "@material-ui/core/Button";
import { JOINED_TRIPS, CREATED_TRIPS } from "../../../navigation/CONSTANTS";
export default function ViewPrfile() {
    const { id } = useParams();
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [points, setPoints] = useState("");
    const [email, setEmail] = useState("");
    const [whatsapplink, setWhatsapplink] = useState("");
    const [facebookLink, setFacebookLink] = useState("");
    const [InstgramLink, setInstgramLink] = useState("");
    const [carModel, setCarModel] = useState("");
    const [carNumber, setCarNumber] = useState("");
    const [carManuFacturingYear, setCarManuFacturingYear] = useState("");
    const [img, setImg] = useState([]);
    const [carimg, setCarImg] = useState([]);
    useEffect(() => {
        let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
            headers = { Authorization: localStorage.getItem("SavedToken") };
        }

        axios
            .get(`https://ijk.expentech.com/api/admin_panel/user_profile`, { headers: headers })
            .then((res) => {
                setUserName(res.data.data.username);
                setPhoneNumber(res.data.data.phone_number);
                setCountryCode(res.data.data.country_code);
                setEmail(res.data.data.email);
                setPoints(res.data.data.points);
                setWhatsapplink(res.data.data.whatsapp_link);
                setFacebookLink(res.data.data.facebook_link);
                setInstgramLink(res.data.data.instgram_link);
                setCarModel(res.data.data.car_model);
                setCarNumber(res.data.data.car_number);
                setCarManuFacturingYear(res.data.data.car_manufacturing_year);


                setImg(res.data.data.image);
                setCarImg(res.data.data.car_image);

            })
            .catch((err) => {
                // console.log(err);
            });
    }, []);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="main-div">
            <Grid container spacing={2} >

            <Grid container spacing={12} >
            <Header title="View User" />
            </Grid>




                <Grid item xs={12}>
                    <div className="userdetails">
                        <div className="userdetailsborder">
                            <h3>Admin Profile Informations</h3>
                            <div>
                                <button className="btn-user-details" onClick={handleClickOpen}>
                                    View userImage
                                </button>
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>UserImage</DialogTitle>

                                    <DialogContent>
                                        <DialogContentText>
                                            <img src={img} height="100" width="200" alt="med1" />
                                        </DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <table className="table">
                            <tr>
                                <th>Username</th>
                                <td>{userName}</td>
                                <th>Phone number</th>
                                <td>{phoneNumber}</td>
                            </tr>
                            <tr>
                                <th>Country code </th>
                                <td>{countryCode}</td>
                                <th> Points</th>
                                <td>{points}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{email}</td>
                                <th>Whatsapp link</th>
                                <td>{whatsapplink}</td>
                            </tr>
                            <tr>
                                <th> Facebook link</th>
                                <td>{facebookLink}</td>
                                <th> Instgram link</th>
                                <td>{InstgramLink}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="userdetails">
                        <div className="userdetailsborder">
                            <h3>car Informations</h3>
                            <div>
                                <button className="btn-user-details" onClick={handleClickOpen}>
                                    View carImage
                                </button>
                                <Dialog open={open} onClose={handleClose}>
                                    <DialogTitle>CarImage</DialogTitle>

                                    <DialogContent>
                                        <DialogContentText>
                                            <img src={carimg} height="100" width="200" alt="med1" />
                                        </DialogContentText>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <table className="table">
                            <tr>
                                <th>Car model</th>
                                <td>{carModel}</td>
                                <th>car number</th>
                                <td>{carNumber}</td>
                            </tr>
                            <tr>
                                <th>Vehicle year of manufacture </th>
                                <td>{carManuFacturingYear}</td>

                            </tr>
                            {/* <tr>
              <th>Email</th>
              <td>{email}</td>
              <th>Whatsapp link</th>
              <td>{whatsapplink}</td>
            </tr> */}
                            {/* <tr>
              <th> Facebook link</th>
              <td>{facebookLink}</td>
              <th> Instgram link</th>
              <td>{InstgramLink}</td>
            </tr> */}
                        </table>
                    </div>

                </Grid>
                <Box className="div-btn-cancel">
                    <Button
                        component={Link}
                        variant="contained"
                        className="btn-view"
                        color="secondary"
                        to="/profile"
                    >
                        Cancel          </Button>
                </Box>
            </Grid>
        </div>
    )
}

