import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import Header from "../../../components/Header";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ImageIcon from "@mui/icons-material/Image";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { randomId } from "@mui/x-data-grid-generator";
import { getAllAnnouncements } from "../../../services";
import axios from "axios";
import Button from "@material-ui/core/Button";
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        Fullname: "",
        Userimage: "",
        Phonenumber: "",
        Email: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <>
      <GridToolbarContainer>
        <Box className="div-btn-cancel">
          <Button
            component={Link}
            variant="contained"
            className="btn-view"
            color="secondary"
            // onClick={handleClick}
            to="/addAnnouncement"
          >
            <AddIcon style={{ color: "white", fontSize: "15px" }} />
            Add Record{" "}
          </Button>
        </Box>
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Announcements = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const colors = tokens(theme.palette.mode);
  const [values, setValues] = useState({
    id: "",
    text: "",
    details: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (id) => () => {
    setOpen(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/announcements/${id}`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        setDataAnnouncement(res.data.data.image);
        console.log(dataAnnouncement);
        setIsLoading(false);
      });
    // setRows(rows.filter((row) => row.id !== id))
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };
  const [storeData, setStoreData] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/announcements`, {
        headers: headers,
      })
      .then((res) => {
        setAnnouncement(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setAnnouncement([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/announcements`, {
        headers: headers,
      })
      .then((res) => {
        setAnnouncement(res.data.data);
        setImg(res.data.data);
        console.log(res.data.data);
        // console.log(res.data.data[0].image);
        // console.log(img);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setAnnouncement([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(values);
    setStoreData(Object.values(values));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState();

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "text",
      headerName: "Title’s Text",
      flex: 1,
      // width: 500, minWidth: 150,
      cellClassName: "name-column--cell",
      editable: true,
    },
    {
      field: "date",
      headerName: "Announcement’s Date",
      type: "text",
      flex: 1,
      // width: 500, minWidth: 150,
      headerAlign: "center",
      align: "center",
      editable: true,
      valueFormatter: (params) =>
        moment(params?.value).format("MMMM DD YYYY -H:mma "),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 500,
      minWidth: 150,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="show Image">
                <ImageIcon />
              </Tooltip>
            }
            label="view"
            onClick={handleClickOpen(id.row.id)}
            color="inherit"
          />,
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Personal Image</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <img
                  height="100"
                  width="200"
                  alt="med1"
                  src={"https://ijk.expentech.com/storage/" + dataAnnouncement}
                />
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>,
        ];
      },
    },
  ];

  return (
    <Box m="2px 5px 0px 5px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Header title="Announcements" />
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.grey[600]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGridPro
          rows={announcement}
          columns={columns}
          editMode="row"
          pageSize={5}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  );
};

export default Announcements;
