


import React from 'react'
import { useState, createContext, useContext } from 'react'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem('SavedToken'))

  const login = user => {
    setUser(user)
    console.log('teste1' + user)
  }

  const logout = () => {

   
    setUser(null)
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  
  return useContext(AuthContext)
}
