import React, { useState, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as sweetalert from "sweetalert";
import { useNavigate ,useParams} from "react-router-dom";
import axios from "axios";
import { getAllLevel } from "../../../services";
import { useForm, Form } from "./useForm1";
import Cookies from "js-cookie";
import Controls from "../global/controls/Controls";
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";

export default function Updateuser() {
  const [formValues, setFormValues] = useState({
    full_name: {
      value: "",
    },
    username: {
      value: "",
    },
    image: {
      value: "",
    },
    phone_number: {
      value: "",
    },
    country_code: {
      value: "",
    },
    email: {
      value: "",
    },
    whatsapp_link: {
      value: "",
    },
    facebook_link: {
      value: "",
    },
    instgram_link: {
      value: "",
    },
    points: {
      value: "",
    },
    car_model: {
      value: "",
    },
    car_number: {
      value: "",
    },
    car_type: {
      value: "",
    },
    // car_image: {
    //   value: "",
    // },
    car_manufacturing_year: {
      value: "",
    },
    Userlevel: {
      value: "",
    },
    level_id: {
      value: "",
    },
    // password: {
    //   value: "",
    // },
    // Cartype: {
    //   value: "",
    // },
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [fullname, setFullname] = useState([]);
  const [username, setUsername] = useState([]);
  const [Image, setImage] = useState([]);
  const [phonenumber, setPhonenumber] = useState([]);
  const [countrycode, setCountrycode] = useState([]);
  const [email, setEmail] = useState([]);
  const [Whatsapplink, setWhatsapplink] = useState([]);
  const [FacebookLink, setFacebookLink] = useState([]);
  const [InstgramLink, setInstgramLink] = useState([]);
  const [Password, setPassword] = useState([]);
  const [Point, setPoint] = useState([]);
  const [CarImage, setCarImage] = useState([]);
  const [CarModel, setCarModel] = useState([]);
  const [CarNumber, setCarNumber] = useState([]);
  const [CarManuFacturingYear, setCarManuFacturingYear] = useState([]);
  const [userlevel, setUserlevel] = useState([]);
  const [userlevel2, setUserlevel2] = useState([]);
  const [Country_code, setCountry_code] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);
  const [level, setlevel] = useState([]);
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
        .get(`https://ijk.expentech.com/api/admin_panel/get_country_codes`, {headers:headers})
        .then((res) => {
          setCountry_code(res.data.data.map(e=>e.iso));
     
      })
     
      axios
        .get(`https://ijk.expentech.com/api/admin_panel/users/${id}`, { headers: headers })
        .then((res) => {
          setFormValues({
            full_name: {
              value: res.data.data.full_name,
            },
            username: {
              value: res.data.data.username,
            },
            email: {
              value:res.data.data.email,
            },
            // image: {
            //   value: res.data.data.image,
            // },
            phone_number: {
              value:res.data.data.phone_number,
            },
            country_code: {
              value:res.data.data.country_code,
            },
            
            whatsapp_link: {
              value: res.data.data.whatsapp_link,
            },
            facebook_link: {
              value:res.data.data.facebook_link,
            },
            instgram_link: {
              value: res.data.data.instgram_link,
            },
            points: {
              value:res.data.data.points,
            },
            // car_image: {
            //   value: res.data.data.car_image,
            // },
            car_model: {
              value:res.data.data.car_model,
            },
            car_number: {
              value:res.data.data.car_number,
            },
            car_type: {
              value:res.data.data.car_type,
            },
            car_manufacturing_year: {
              value: res.data.data.car_manufacturing_year,
            },
            Userlevel: {
              value:res.data.data.level.name,
            },
            // password: {
            //   value:res.data.data.password,
            // },
            // Cartype: {
            //   value:res.data.data.car_type,
            // },
            level_id: {
              value:res.data.data.level.id,
            },
          });
        })
      }, []);
      
      
  useEffect(() => {

    getAllLevel()
      .then((res) => {
        console.log(res.data);
        setlevel(res.data);
        
      })
      .catch((err) => {
        console.log("axios err=", err);
        setlevel([]);
        
      });
    
      return () => {
        console.log("axios cleanup.");
      };
    }, []);
  
  const initialFValues = {
    full_name: "",
    username: "",
    phone_number: "",
    country_code: "",
    email: "",
    level_id: "",
    points: "",
    // image: "",
    whatsapp_link: "",
    facebook_link: "",
    instgram_link: "",
    // password: "",
  };
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

    // if ("full_name" in fieldValues){

    //   if(fieldValues.full_name == ''){
    //     temp.full_name = "This is field is required";
        
    //   }else{
        
    //     temp.full_name = "";
    //   }
    
    // }
    // if ("username" in fieldValues){
      
    //   if(fieldValues.username == ''){
    //     temp.username = "This is field is required";
        
    //   }else{
        
    //     temp.username = "";
    //   }
      
    // }
    // if ("phone_number" in fieldValues){

    //   if(fieldValues.phone_number == ''){
    //     temp.phone_number = "This is field is required";
     
    //   }else{
        
    //     temp.phone_number = "";
    //   }
    
    // }
    // if ("country_code" in fieldValues){

    //   if(fieldValues.country_code == ''){
    //     temp.country_code = "This is field is required";
     
    //   }else{
        
    //     temp.country_code = "";
    //   }
    
    // }
    // if ("email" in fieldValues){

    //   if(fieldValues.email == ''){
    //     temp.email = "This is field is required";
     
    //   }else{
        
    //     temp.email = "";
    //   }
    
    
    // }
    // if ("level_id" in fieldValues){

    //   if(fieldValues.level_id == ''){
    //     temp.level_id = "This is field is required";
     
    //   }else{
        
    //     temp.level_id = "";
    //   }
    
    
    // }
    // if ("points" in fieldValues){

    //   if(fieldValues.points == ''){
    //     temp.points = "This is field is required";
     
    //   }else{
        
    //     temp.points = "";
    //   }
    
    
    // }
    // if ("image" in fieldValues){

    //   if(fieldValues.image == ''){
    //     temp.image = "This is field is required";
     
    //   }else{
        
    //     temp.image = "";
    //   }
    
    
    // }
    // if ("whatsapp_link" in fieldValues){

    //   if(fieldValues.whatsapp_link == ''){
    //     temp.whatsapp_link = "This is field is required";
     
    //   }else{
        
    //     temp.whatsapp_link = "";
    //   }
    
    
    // }
    // if ("facebook_link" in fieldValues){

    //   if(fieldValues.facebook_link == ''){
    //     temp.facebook_link = "This is field is required";
     
    //   }else{
        
    //     temp.facebook_link = "";
    //   }
    
    
    // }
    // if ("instgram_link" in fieldValues){

    //   if(fieldValues.instgram_link == ''){
    //     temp.instgram_link = "This is field is required";
     
    //   }else{
        
    //     temp.instgram_link = "";
    //   }
    
    
    // }
    // if ("password" in fieldValues){

    //   if(fieldValues.password == ''){
    //     temp.password = "This is field is required";
     
    //   }else{
        
    //     temp.password = "";
    //   }
    
    
    // }


    setErrors({
      ...temp,
    });
    if (fieldValues == loginInput)
      return Object.values(temp).every((x) => x == "");
  };
  console.log(formValues);
  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handleInputfile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, validate);
  const handleChange = (event) => {
    event.persist();
    setFormValues({ ...formValues,[event.target.name]: event.target.value});
  
  };
  const handlefile1 = (event) => {
    event.persist();
    setFormValues({ ...formValues, image: event.target.files[0]});
  //  debugger

  };
  const handleSubmit = (e) => {

    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    // if (validate()) {
        e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("full_name", e.target.full_name.value);
        bodyFormData.append("username",  e.target.username.value);
        bodyFormData.append("phone_number", e.target.phone_number.value);
        bodyFormData.append("country_code",e.target.country_code.value);
        bodyFormData.append("email", e.target.email.value);
        bodyFormData.append("level_id", e.target.level_id.value);
        bodyFormData.append("points", e.target.points.value);
        // bodyFormData.append("image",'');
        bodyFormData.append("whatsapp_link",e.target.whatsapp_link.value);
        bodyFormData.append("facebook_link",e.target.facebook_link.value);
        bodyFormData.append("instgram_link",e.target.instgram_link.value);
        // bodyFormData.append("password", formValues.password.value);
        // bodyFormData.append("car_image", formValues.car_image.value);
        bodyFormData.append("car_model", e.target.car_model.value);
        bodyFormData.append("car_number", e.target.car_number.value);
        bodyFormData.append("car_manufacturing_year", e.target.car_manufacturing_year.value);
        bodyFormData.append("car_type",e.target.car_type.value);
        bodyFormData.append("_method", "put");
   
        axios
          .post(`https://ijk.expentech.com/api/admin_panel/users/${id}`, bodyFormData,{headers:headers})
          .then((res) => {
            console.log(res.status);
            if (res.status  === 200) {
              sweetalert("edit successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/users");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              setLogin({ ...loginInput, error_list: res.data.error });
            }
          });
      // };
    }
      
   
  

  return (
    <div className="main-div">
      <h3 className="titlepage">Update User</h3>
      <form
      className="form-group"
        method="post"
        enctype="multipart/form-data"
        noValidate
        onSubmit={handleSubmit}
        >
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Full Name"
                className="form-control"
                label="Full Name"
                name="full_name"
                fullWidth
                required
                type="text"
                onChange={handleChange}
                value={formValues.full_name.value}
            
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="User Name"
                className="form-control"
                label="User name"
                name="username"
                fullWidth
                required
                type="text"
                onChange={handleChange}
                value={formValues.username.value}
            />
          </Grid>
     
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="phone number"
                className="form-control"
                label="phone number"
                name="phone_number"
                fullWidth
                required
                value={formValues.phone_number.value}
                type="text"
              onChange={handleChange}
              error={errors.phone_number}
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <select
                  className="form-control"
                  name="country_code"
                  onChange={(e) => setNewcountrycode(e.target.value)}
                >
                <option value={countrycode} disabled>Chooes one option</option>
                  {Country_code.map((opts,i) => (
                    <option key={i} value={opts}>
                      {opts}
                    </option>
                  ))}
                </select>
              </FormControl>
            </Grid>*/}
            <Grid item md={6} xs={12}> 
        
            <div class="select">
              <select 
              class="select-text"
              required
              name="country_code"
                onChange={handleChange}>
                <option selected disabled value={formValues.country_code.value}>{formValues.country_code.value}</option>
                {Country_code.map((opts,i) => (
                  <option key={i} value={opts}>
                    {opts}
                  </option>
                ))}
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
              <label class="select-label"></label>
            </div>
         </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="email"
                className="form-control"
                label="Email"
                name="email"
                fullWidth
                type="text"
                value={formValues.email.value}
                onChange={handleChange}
              
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <select
                  className="form-control"
                  name="level"
                  onChange={(e) => setNewcountrycode(e.target.value)}
                >
                <option value={userlevel} selected disabled>{userlevel}</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts}>
                      {opts.name}
                    </option>
                  ))}
                </select>
              </FormControl>
            </Grid> */}
            <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="level_id"
                  onChange={handleChange}
                  >
                  <option value={formValues.level_id.value} selected disabled>{formValues.Userlevel.value}</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts.id}>
                      {opts.name}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label"></label>
              </div>
       

          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="points"
                className="form-control"
                label="points"
                name="points"
                value={formValues.points.value}
                fullWidth
                required
                type="text"
              onChange={handleChange}
              error={errors.points}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder=""
                className="form-control"
                // label="Image"
                name="image"
                fullWidth
            
                type="file"
                // value={formValues.image.value}
              onChange={handlefile1}
            />

          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="whatsapp_link"
                className="form-control"
                label="whatsapp link"
                name="whatsapp_link"
                fullWidth
                
                value={formValues.whatsapp_link.value}
                type="text"
              onChange={handleChange}
              error={errors.whatsapp_link}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="facebook_link"
                className="form-control"
                label="facebook link"
                name="facebook_link"
                fullWidth
                
                value={formValues.facebook_link.value}
                type="text"
              onChange={handleChange}
              error={errors.facebook_link}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="instgram_link"
                className="form-control"
                label="instgram link"
                name="instgram_link"
                fullWidth
                
                type="text"
                value={formValues.instgram_link.value}
              onChange={handleChange}
              error={errors.instgram_link}
            />
           </Grid>  
          {/* <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="password"
                className="form-control"
                // label="password"
                name="password"
                fullWidth
                required
                type="password"
                value={formValues.password.value}
              onChange={handleChange}
              error={errors.password}
            />
          </Grid> */}
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Car model"
                className="form-control"
                label="Car model"
                name="car_model"
                fullWidth
                
                type="text"
                value={formValues.car_model.value}
              onChange={handleChange}
              error={errors.car_model}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Car Number"
                className="form-control"
                label="Car Number"
                name="car_number"
                fullWidth
                
                type="text"
                value={formValues.car_number.value}
              onChange={handleChange}
              error={errors.car_number}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder=" Vehicle year of manufacture "
                className="form-control"
                label="Vehicle year of manufacture "
                name="car_manufacturing_year"
                fullWidth
                
                type="text"
                value={formValues.car_manufacturing_year.value}
                onChange={handleChange}
                error={errors.car_manufacturing_year}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="car type"
                className="form-control"
                label="car type "
                name="car_type"
                fullWidth
                type="text"
                value={formValues.car_type.value}
                onChange={handleChange}
                error={errors.car_type}
            />
          </Grid>
    
          </Grid>
          
         
        {/* <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            // onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid> */}
        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Button
            type="submit"
            variant="contained"
            className="btn-submit"
            color="secondary"
          >
          submit
          </Button>
        </Grid>
        </form>
    </div>
  );
}
