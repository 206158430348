import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../../services";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ImageIcon from "@mui/icons-material/Image";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../global/ConfirmDialog";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import JoinRightIcon from "@mui/icons-material/JoinRight";
import Tooltip from "@mui/material/Tooltip";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { randomId } from "@mui/x-data-grid-generator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as sweetalert from "sweetalert";
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;



 
  const navigate = useNavigate();
  const handleUpdateClick = (id) => () => {
    navigate(`/update-user/${id}`, { state: { id: id } });
  };
  return (
  <>
    
         <GridToolbarContainer>
         <Box className="div-btn-cancel">
          <Button
            component={Link}
            variant="contained"
            className="btn-view"
            color="secondary"
            to="/Add-new"
          >
             <AddIcon style={{ color: "white", fontSize: "15px" }} />
            Add User          </Button>
        </Box>
        
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
   const [lastDeletedTitle, setLastDeletedTitle] = useState("");
  // const [confirmDialog, setConfirmDialog] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [rows, setRows] = React.useState();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    getAllUsers()
      .then((res) => {
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setUsers([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  
  useEffect(() => {
    if (lastDeletedTitle !== "") {
      sweetalert("Has upgrade to Marshall", {
        className: "alert-div",
        button: {
          text: "ok",
        },
      });
   
      getAllUsers().then((res) => {
        setUsers(res.data);
        
      });
    }
  }, [lastDeletedTitle]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  let headers = {};
  if (localStorage.getItem("SavedToken") !== null) {
    headers = { Authorization: localStorage.getItem("SavedToken") };
  }
  const handleDeleteClick = id => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }

    axios
      .delete(`https://ijk.expentech.com/api/admin_panel/users/${id}`,  { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          sweetalert("okت العملية بنجاح", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
          setUsers(users.filter((row) => row.id !== id));
        } else {
          sweetalert("هذا المستخدم غير موجود", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      })
      .catch((err) => {});
  };
  const [ALLUsers, setALLUsers] = useState([]);
  const [fullname, setFullname] = useState([]);
  const [username, setUsername] = useState([]);
  const [Image, setImage] = useState([]);
  const [phonenumber, setPhonenumber] = useState([]);
  const [countrycode, setCountrycode] = useState([]);
  const [email, setEmail] = useState([]);
  const [Whatsapplink, setWhatsapplink] = useState([]);
  const [FacebookLink, setFacebookLink] = useState([]);
  const [InstgramLink, setInstgramLink] = useState([]);
  const [Password, setPassword] = useState([]);
  const [Point, setPoint] = useState([]);
  const [userlevel, setUserlevel] = useState([]);
  const [Countrycode, setCountrycod] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);
  const [level, setlevel] = useState([]);
  const handleDeleteClick1 = id => {
      axios
        .get(`https://ijk.expentech.com/api/admin_panel/users/${id}`, { headers: headers })
        .then((res) => {
          console.log(res)
          setFullname(res.data.data.full_name);
          setUsername(res.data.data.username);
          setImage(res.data.data.image);
          setPhonenumber(res.data.data.phone_number);
          setCountrycod(res.data.data.country_code);
          setEmail(res.data.data.email);
          setWhatsapplink(res.data.data.whatsapp_link);
          setFacebookLink(res.data.data.facebook_link);
          setInstgramLink(res.data.data.instgram_link);
          setPassword(res.data.data.password);
          setPoint(res.data.data.points);
          setUserlevel(res.data.data.level.name);
        })

        const bodyFormData = new FormData();
        bodyFormData.append("full_name",fullname);
        bodyFormData.append("username", username);
        bodyFormData.append("phone_number", phonenumber);
        bodyFormData.append("country_code", Countrycode);
        bodyFormData.append("email", email);
        bodyFormData.append("level_id",userlevel);
        bodyFormData.append("image", Image);
        bodyFormData.append("whatsapp_link",Whatsapplink);
        bodyFormData.append("facebook_link",FacebookLink);
        bodyFormData.append("instgram_link",InstgramLink);
        bodyFormData.append("password",Password);
        bodyFormData.append("points",Point);

    

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/upgrade_user/${id}`, { headers: headers },bodyFormData,)
      .then((res) => {
        // console.log(res)
        if (res.data.code === 200) {
          setLastDeletedTitle(res);
       
          // setUsers(users.filter((row) => row.id !== id));
        } else {
          sweetalert("not found user id", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      })
      .catch((err) => {});
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
  };
  const handleViewClick = (id) => () => {
    navigate(`/view-user/${id}`, { state: { id: id } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (id) => () => {
    setOpen(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/users/${id}`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        setDataAnnouncement(res.data.data.image);
        console.log(dataAnnouncement);
        setIsLoading(false);
      });
    // setRows(rows.filter((row) => row.id !== id))
  };

  const [open1, setOpen1] = React.useState(false);
  const handleClickOpen1 = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    setIsLoading(true);

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/users`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data.data);
        setALLUsers(res.data.data);
        setIsLoading(false);
       
      })
      .catch((err) => {
        console.log("axios err=", err);
        setUsers([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "User ID",
      type: "text",
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "full_name",
      headerName: "Full name",
      type: "text",
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      minWidth: 150,
      maxWidth: 500,
      cellClassName: "actions",
      getActions: (id ) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              
                navigate(`/update-user/${id.row.id}`, { state: { id: id } });
            }}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          color="inherit"
          onClick={() => {
            console.log("12");
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure about this procedure?",
              onConfirm: () => {
                console.log("123");
                handleDeleteClick(id.row.id);
              },
            });
          }}
        />,
        <GridActionsCellItem
        icon={
          <Tooltip title="user to Marshall">
            <JoinRightIcon />
          </Tooltip>
        }
        label="view"
        color="inherit"
        onClick={() => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure about this procedure?",
            onConfirm: () => {
              handleDeleteClick1(id.row.id);
            },
          });
        }}
      />,

         <ConfirmDialog
         confirmDialog={confirmDialog}
         setConfirmDialog={setConfirmDialog}
       />,
          <GridActionsCellItem
            icon={<VisibilityRoundedIcon />}
            label="View"
            onClick={handleViewClick(id.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<UpgradeIcon />}
            label="View"
            onClick={()=>{
              navigate(`/update_user_trip_level/${id.row.id}`, { state: { id: id } });
            }}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={
            <Tooltip title="show Image">
              <ImageIcon />
            </Tooltip>
          }
          label="view"
          onClick={handleClickOpen(id.row.id)}
          color="inherit"
        />,
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Personal Image</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <img
                height="100"
                width="200"
                alt="med1"
                src={"https://ijk.expentech.com/storage/" + dataAnnouncement}
              />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>,
            // ==========================================
        ];
      },
    },
  ];

  return (
    <div className="main-div">
      <Header title="All Users" />
    <Box >
      <Box display="flex" justifyContent="space-between">
        <Box>
          
        </Box>
      </Box>

      <Box
      
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.grey[600]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGridPro
          rows={users}
          columns={columns}
          editMode="row"
          pageSize={5}
          pagination
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
          }}
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
    </div>
  );
};

export default Users;
