import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { useNavigate } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import * as sweetalert from "sweetalert";

import {Grid,FormControl} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../global/controls/Controls";
import { useParams } from "react-router-dom";
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, WebApiAdaptor } from '@syncfusion/ej2-data';
// import { MenuProps, useStyles, fullname } from "./utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));
function Addmembertrip() {
  useEffect(()=>{
    var element =  document.querySelector("body > :nth-child(3)");
    element.classList.add('slick-dots-test');
  



  },[])
  const navigate = useNavigate();
  const [fullname, setFullname] = useState([]);
  const divStyle = {
    // margin: 10,
    
  }


const { id } = useParams();
console.log(id)
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selected2, setSelected2] = useState([]);
  const [datatrip, setdatatrip] = useState({
    trip_id: id,
    users: [],
  });
  let usersid=[];
  const [Search, setSearch] = useState([1]);
  let sportsData=[];
  const isAllSelected = fullname.length > 0 && selected.length === fullname.length;
  const handleChange = (event) => {
     var element =  document.querySelector("body >:nth-child(3)");
    element.classList.add('slick-dots-test');
        let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
          headers = { Authorization: localStorage.getItem("SavedToken") };
        }
        const Search = event.target.value;
     
        if(Search == 1){
            axios
        .get(`https://ijk.expentech.com/api/admin_panel/users`, { headers: headers })
        .then((res) => {
          setFullname(res.data.data)
        })
        }
        else if(Search == 2){
            axios
            .get(`https://ijk.expentech.com/api/admin_panel/users`, { headers: headers })
            .then((res) => {
              setFullname(res.data.data)
       
          })
        }
        else if(Search == 3){
          axios
          .get(`https://ijk.expentech.com/api/admin_panel/users`, { headers: headers })
          .then((res) => {
            setFullname(res.data.data)
         
          })
          
        }
            setSearch(Search);
      };
      function handele (e){
       setdatatrip({...datatrip,users:e.target.value});
       
       
      }
    function handleClick(e) {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      e.preventDefault();
      // const bodyFormData = new FormData();
      // bodyFormData.append("trip_id",id );
      // bodyFormData.append("users",users);
      // setdatatrip({users:usersid});
      // debugger;
      // console.log(datatrip);
      // debugger;
      axios
        .post(`https://ijk.expentech.com/api/admin_panel/add_member_to_trip`,datatrip,{ headers: headers })
          .then((res) => {
            if (res.status  === 200) {
              sweetalert("Added successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/trips");
            } else {
              sweetalert("Error in adding", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
            
            }
          });
         
        // })
        
  
    }

 
  return (
    <div className="main-div">
  
    <h3 className="titlepage"> Add user to Trip</h3> 
    <Grid container maxWidth="sm" spacing={4}>
    

        <Grid item md={6} xs={12}>
        
        <div class="select">
          <select 
          
          class="select-text"
          onChange={handleChange}
          >
            <option value='' selected disabled>Select the search type on member</option>
            <option value='1'>Search by full name</option>
            <option value='2'>Search by User name</option>
            <option value='3'>Search by Email</option>
          </select>
          <span class="select-highlight"></span>
          <span class="select-bar"></span>
          <label class="select-label">Select the search type on member</label>
        </div>
        </Grid>
        <Grid item md={6} xs={12}>
        {(function() {
          if (Search == 1) {
            return (
              <Grid>

              <div style={divStyle}>
            
      <label className="select-label">Users who are not organized for Trip</label>
                <MultiSelectComponent
                  placeholder="Select "
                  dataSource={fullname}
                  fields={{value:"id", text:"full_name"}}
                  onChange={handele}
                ></MultiSelectComponent>
              </div>
          </Grid>
        )
          }
  
           else if( Search ==2){
            return (
              <Grid>

              <div style={divStyle}>


                <MultiSelectComponent
                  placeholder="Select "
                  dataSource={fullname}
                  fields={{value:"id", text:"username"}}
                  onChange={handele}
                ></MultiSelectComponent>
              </div>
              </Grid>
            );
          }
           else if( Search ==3){
            return (
              <Grid>

              <div style={divStyle}> 


                <MultiSelectComponent
                  placeholder="Select "
                  dataSource={fullname}
                  fields={{value:"id", text:"email"}}
                  onChange={handele}
                ></MultiSelectComponent>
              </div>
              </Grid>
            );
          }
        })()}
        </Grid>
        
        <Grid item md={6} xs={12} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={handleClick}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
    </Grid>

    
     </div>
  );
}

 export default Addmembertrip;
