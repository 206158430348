import React, { useState, useEffect } from "react";

import * as sweetalert from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import {
  Button,
  TextField,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  RadioGroup,
  Box,
  Autocomplete,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { useLocation } from "react-router-dom";
export default function EditProile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [levels, setlevels] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);
  const [Country_code, setCountry_code] = useState([]);
  const [level, setlevel] = useState([]);
  const [levelid, setLevelId] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [imager, setImager] = useState();
  const [selectedImageCar, setSelectedImageCar] = useState();
  const [formValues, setFormValues] = useState({
    full_name: {
      value: "",
    },
    username: {
      value: "",
    },
    image: {
      value: "",
    },
    phone_number: {
      value: "",
    },
    country_code: {
      value: "",
    },
    points: {
      value: "",
    },
    email: {
      value: "",
    },
    whatsapp_link: {
      value: "",
    },
    facebook_link: {
      value: "",
    },
    instgram_link: {
      value: "",
    },
    car_image: {
      value: "",
    },
    car_model: {
      value: "",
    },
    car_number: {
      value: "",
    },
    car_manufacturing_year: {
      value: "",
    },

    password: {
      value: "",
    },
  });
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/get_country_codes`, {
        headers: headers,
      })
      .then((res) => {
        setlevel(res.data.data);
        setCountry_code(res.data.data.map(e => e.iso));

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [imageUrl, setImageUrl] = useState();
  const [imageCarUrl, setImageCarUrl] = useState();
  useEffect(() => {
    if (location.pathname == `/edit/${id}` || location.pathname == `/editProfile/${id}`) {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      axios
        .get(`https://ijk.expentech.com/api/admin_panel/user_profile`, {
          headers: headers,
        })
        .then((res) => {
          setLevelId(res.data.data.level.id);
          setFormValues({
            full_name: {
              value: res.data.data.full_name,
            },
            username: {
              value: res.data.data.username,
            },
            image: {
              value: res.data.data.image,
            },

            phone_number: {
              value: res.data.data.phone_number,
            },
            country_code: {
              value: res.data.data.country_code,
            },
            points: {
              value: res.data.data.points,
            },
            email: {
              value: res.data.data.email,
            },
            whatsapp_link: {
              value: res.data.data.whatsapp_link,
            },
            facebook_link: {
              value: res.data.data.facebook_link,
            },
            instgram_link: {
              value: res.data.data.instgram_link,
            },
            car_image: {
              value: res.data.data.car_image,
            },

            car_model: {
              value: res.data.data.car_model,
            },
            car_number: {
              value: res.data.data.car_number,
            },
            car_manufacturing_year: {
              value: res.data.data.car_manufacturing_year,
            },
            password: {
              value: '',
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));
  const handleChange = (e) => {
    e.persist();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handlefile = (e) => {
    e.persist();
    setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
  };

  useEffect(() => {

    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }

  }, [selectedImage]);


  useEffect(() => {

    if (selectedImageCar) {
      setImageCarUrl(URL.createObjectURL(selectedImageCar));
    }

  }, [selectedImageCar]);


  const handleSubmit = (e) => {
    if (location.pathname == `/edit/${id}` || location.pathname == `/editProfile/${id}`) {
      e.preventDefault();
      // console.log(formValues.image.value);
      // if (formValues.image.value == "") {
      //   sweetalert("User image is required", {
      //     className: "alert-div",
      //     button: {
      //       text: "ok",
      //     },
      //   });
      // } else if (formValues.car_image.value == "") {
      //   sweetalert("car image is required", {
      //     className: "alert-div",
      //     button: {
      //       text: "ok",
      //     },
      //   });
      // }
      if (formValues.password.value == "") {
        sweetalert("password is required and must be greater than 8 characters", {
          className: "alert-div",
          button: {
            text: "ok",
          },
        });
      }
      else {

        const bodyFormData = new FormData();
        bodyFormData.append("full_name", e.target.full_name.value);
        bodyFormData.append("username", e.target.username.value);
        bodyFormData.append("phone_number", e.target.phone_number.value);
        bodyFormData.append("country_code", e.target.country_code.value);
        bodyFormData.append("email", e.target.email.value);
        bodyFormData.append("points", e.target.points.value);
        if (selectedImage == null) {
          // console.log(formValues.image.value);
          bodyFormData.append("image", null);
        } else {
          bodyFormData.append("image", selectedImage);
        }
        bodyFormData.append("whatsapp_link", e.target.whatsapp_link.value);
        bodyFormData.append("facebook_link", e.target.facebook_link.value);
        bodyFormData.append("instgram_link", e.target.instgram_link.value);
        bodyFormData.append("password", e.target.password.value);
        if (selectedImageCar == null) {

          bodyFormData.append("image", null);
        } else {
          bodyFormData.append("car_image", selectedImageCar);
        }
        bodyFormData.append("car_model", e.target.car_model.value);
        bodyFormData.append("car_number", e.target.car_number.value);
        bodyFormData.append(
          "car_manufacturing_year",
          e.target.car_manufacturing_year.value
        );
        bodyFormData.append("points", e.target.points.value);
       
        bodyFormData.append("level_id", levelid);


        let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
          headers = { Authorization: localStorage.getItem("SavedToken") };
        }
        axios
          .post(
            `https://ijk.expentech.com/api/admin_panel/update_profile`,

            bodyFormData,
            { headers: headers }
          )
          .then((res) => {
            console.log(res.data.code);
            if (res.data.code == 200) {
              sweetalert("updated successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/profile");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
            }
          });
      }

    } else {
      const bodyFormData = new FormData();
      bodyFormData.append("full_name", formValues.full_name);
      bodyFormData.append("username", formValues.username);
      bodyFormData.append("phone_number", formValues.phone_number);
      bodyFormData.append("country_code", levels);
      bodyFormData.append("email", formValues.email);
      bodyFormData.append("points", formValues.points);
      bodyFormData.append("image", formValues.image);
      bodyFormData.append("whatsapp_link", formValues.whatsapp_link);
      bodyFormData.append("facebook_link", formValues.facebook_link);
      bodyFormData.append("instgram_link", formValues.instgram_link);
      bodyFormData.append("password", formValues.password);
      bodyFormData.append("car_image", formValues.car_image);
      bodyFormData.append("car_model", formValues.car_model);
      bodyFormData.append("car_number", formValues.car_number);
      bodyFormData.append(
        "car_manufacturing_year",
        formValues.car_manufacturing_year
      );
      bodyFormData.append("points", formValues.points);

      e.preventDefault();

      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      axios
        .post(
          `https://ijk.expentech.com/api/admin_panel/marshalls`,

          bodyFormData,
          { headers: headers }
        )
        .then((res) => {
          if (res.data.code == 200) {
            // sweetalert("ok الاضافة بنجاح", {
            //   className: "alert-div",
            //   button: {
            //     text: "ok",
            //   },
            // });
            // navigate("/marshalls");
          } else {
            sweetalert(res.data.message, {
              className: "alert-div",
              button: {
                text: "ok",
              },
            });
          }
        });
    }
  };

  return (
    <div className="main-div">

      <h3 className="titlepage">Edit marshalle </h3>

      <form
        className="form-group"
        method="post"
        enctype="multipart/form-data"
        noValidate
        onSubmit={handleSubmit}
      >
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Full name "
              className="form-control"
              label="Full name "
              name="full_name"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.full_name.value}
              onChange={handleChange}
            // helperText={
            //   formValues.full_name.error &&
            //   formValues.full_name.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Username "
              className="form-control"
              label="Username "
              name="username"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.username.value}
              onChange={handleChange}
            // error={formValues.username.error}
            // helperText={
            //   formValues.username.error && formValues.username.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            {" "}
            <TextField
              placeholder="Phone Number"
              className="form-control"
              label="Phone Number"
              name="phone_number"
              variant="outlined"
              fullWidth
              required
              value={formValues.phone_number.value}
              type="text"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            {" "}
            <div class="select">
              <select
                class="select-text"
                required
                name="country_code"
                onChange={(e) => setNewcountrycode(e.target.value)}>
                <option selected disabled value={formValues.country_code.value}>{formValues.country_code.value}</option>
                {Country_code.map((opts, i) => (
                  <option key={i} value={opts}>
                    {opts}
                  </option>
                ))}
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
              <label class="select-label"></label>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            {" "}
            <TextField
              placeholder="Points"
              className="form-control"
              label="Points"
              name="points"
              variant="outlined"
              fullWidth
              required
              type="number"
              value={formValues.points.value}
              onChange={handleChange}
            // error={formValues.points.error}
            // helperText={
            //   formValues.points.error && formValues.points.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Email"
              className="form-control"
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.email.value}
              onChange={handleChange}
            // error={formValues.email.error}
            // helperText={
            //   formValues.email.error && formValues.email.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Whatsapp link "
              className="form-control"
              label="Whatsapp link "
              name="whatsapp_link"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.whatsapp_link.value}
              onChange={handleChange}
            // error={formValues.whatsapp_link.error}
            // helperText={
            //   formValues.whatsapp_link.error &&
            //   formValues.whatsapp_link.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Facebook link "
              className="form-control"
              label="Facebook link "
              name="facebook_link"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.facebook_link.value}
              onChange={handleChange}
            // error={formValues.facebook_link.error}
            // helperText={
            //   formValues.facebook_link.error &&
            //   formValues.facebook_link.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Instgram link "
              className="form-control"
              label="Instgram link"
              name="instgram_link"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.instgram_link.value}
              onChange={handleChange}
            // error={formValues.instgram_link.error}
            // helperText={
            //   formValues.instgram_link.error &&
            //   formValues.instgram_link.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="password"
              className="form-control"
              label="password"
              name="password"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.password.value}
              onChange={handleChange}
              error={formValues.password.error}
            // helperText={
            //   formValues.password.error &&
            //   formValues.password.errorMessage
            // }
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Car model"
              className="form-control"
              label="Car model"
              name="car_model"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.car_model.value}
              onChange={handleChange}
            // error={formValues.car_model.error}
            // helperText={
            //   formValues.car_model.error &&
            //   formValues.car_model.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              placeholder="car number"
              className="form-control"
              label="car number"
              name="car_number"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.car_number.value}
              onChange={handleChange}
            // error={formValues.car_number.error}
            // helperText={
            //   formValues.car_number.error &&
            //   formValues.car_number.errorMessage
            // }
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              placeholder="Vehicle year of manufacture"
              className="form-control"
              label="Vehicle year of manufacture"
              name="car_manufacturing_year"
              variant="outlined"
              fullWidth
              required
              type="text"
              value={formValues.car_manufacturing_year.value}
              onChange={handleChange}
            // error={formValues.car_manufacturing_year.error}
            // helperText={
            //   formValues.car_manufacturing_year.error &&
            //   formValues.car_manufacturing_year.errorMessage
            // }
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <div>
              <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: "none" }}
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
              <div className="updateImage">
                <label htmlFor="select-image">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image
                  </Button>

                </label>
                {selectedImage == null ? (
                  <Box mt={2} textAlign="center">

                    <img src={'https://ijk.expentech.com/storage/' + formValues.image.value} height="100px" />
                  </Box>

                ) : (
                  <Box mt={2} textAlign="center">
                    <img src={imageUrl}
                      alt={selectedImage.name} height="100px" />
                  </Box>
                )

                }
              </div>



            </div>


          </Grid>

          <Grid item md={3} xs={12}>
            <div>
              <input
                accept="image/*"
                type="file"
                id="select-image-car"
                name="car_image"
                style={{ display: "none" }}
                onChange={(e) => setSelectedImageCar(e.target.files[0])}
              />
              <div className="updateImage">
                <label htmlFor="select-image-car">
                  <Button variant="contained" color="primary" component="span">
                    Upload Car Image
                  </Button>

                </label>
                {selectedImageCar == null ? (
                  <Box mt={2} textAlign="center">
                    <img src={'https://ijk.expentech.com/storage/' + formValues.car_image.value} height="100px" />
                  </Box>
                ) : (
                  <Box mt={2} textAlign="center">
                    <img src={imageCarUrl}
                      alt={selectedImageCar.name} height="100px" />
                  </Box>
                )

                }
              </div>
            </div>
          </Grid>
        </Grid>



        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Button
            type="submit"
            variant="contained"
            className="btn-submit"
            color="secondary"
          >
            submit
          </Button>
        </Grid>
      </form>
    </div >
  );
}
