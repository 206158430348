import React, { useState, useEffect,useRef, useMemo } from "react";
import * as sweetalert from "sweetalert";
import { useNavigate,useParams } from "react-router-dom";
import axios from "axios";
import { useForm, Form } from "./useForm1";
import Controls from "../global/controls/Controls";
import { getAllLevel } from "../../../services";
import {Grid,FormControl} from "@material-ui/core";
import MyComponent from '../Google/google'
import JoditEditor from "jodit-react"
import { positions } from "@mui/system";
import { format } from 'date-fns';
export default function Updatetrip() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [level, setlevel] = useState([]);
  const [levels,setlevels] = useState([]);
  const [camped,setcamped] = useState([]);
 
 //get data in trip
  const [Tripname,setTripname] = useState('');
  const [StartDate,setStartDate] = useState([]);
  const [Location,setLocation] = useState([]);
  const [Capacity,setCapacity] = useState([]);
  const [Minimumpnumber,setMinimumpnumber] = useState([]);
  const [Tripplace,setTripplace] = useState([]);
  const [llongitude,setlongitude] = useState([]);
  const [llatitude,setllatitude] = useState([]);
  const [Description,setDescription] = useState([]);
  const [Levelid,setLevelid] = useState([]);
  const [Endtime,setEndtime] = useState([]);
  const [Frequency,setFrequency] = useState([]);
  const [Camped,setCamped] = useState([]);
  const [Img,setImg] = useState([]);


  const [desc,setDesc] = useState('');
  const initialFValues = {
    name: "",
    start_date: "",
    location: "",
    capacity: "",
    minimum_pnumber: "",
    trip_place: "",
    longitude: "",
    latitude: "",
    description: "",
    level_id: "",
    end_time: "",
    frequency: "",
    is_camped:" ",
    image:" ",
  };
  const [formValues, setFormValues] = useState({
    name: {
      value: "",
    },
    start_date: {
      value: "",
    },
    location: {
      value: "",
    },
    capacity: {
      value: "",
    },
    minimum_pnumber: {
      value: "",
    },
    trip_place: {
      value: "",
    },
    longitude: {
      value: "",
    },
    latitude: {
      value: "",
    },
    description: {
      value: "",
    },
    level_id: {
      value: "",
    },
    level_name: {
      value: "",
    },
    end_time: {
      value: "",
    },
    frequency: {
      value: "",
    },
    is_camped: {
      value: "",
    },
    image: {
      value: "",
    },
  });
     const [selectedImage, setSelectedImage] =useState();
     const [selectedImage1, setSelectedImage1] =useState();
     const [selectedImage2, setSelectedImage2] =useState();
   console.log(selectedImage);
const [Updateimage, setUpdateimage] =useState('');
 console.log(Updateimage);
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    
    axios
        .get(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, { headers: headers })
        .then((res) => {
          console.log(res.data)
          setSelectedImage(res.data.image);
          setUpdateimage("https://ijk.expentech.com/storage/"+res.data.image)
          // const imagetrip="https://ijk.expentech.com/storage/"+String(selectedImage);
          // setSelectedImage2(imagetrip);
          // console.log(selectedImage2);
          setTripname(res.data.name);
          // setTripname( ...Tripname, [event.target.name]: event.target.value })[...Tripname, res.data.name]);
          setFormValues({
            name: {
              value: res.data.name,
            },
            start_date: {
              value: res.data.start_date,
            },
            location: {
              value: res.data.location,
            },
            capacity: {
              value:res.data.capacity,
            },
            minimum_pnumber: {
              value:res.data.minimum_pnumber,
            },
            trip_place: {
              value: res.data.trip_place,
            },
            longitude: {
              value: res.data.longitude,
            },
            latitude: {
              value:res.data.latitude,
            },
            description: {
              value: res.data.description,
            },
            level_id: {
              value: res.data.level.id,
            },
            level_name: {
              value: res.data.level.name,
            },
            end_time: {
              value: res.data.end_time,
            },
            frequency: {
              value: res.data.frequency,
            },
            is_camped: {
              value: res.data.is_camped,
            },
            // image: {
            //   value: res.data.image,
            // },
          });
          // setStartDate(res.data.start_date);
          // setLocation(res.data.location);
          // setCapacity(res.data.capacity);
          // setMinimumpnumber(res.data.minimum_pnumber);
          // setTripplace(res.data.trip_place);
          // setlongitude(localStorage.getItem("longtitude"));
          // setllatitude(localStorage.getItem("latitude"));
          // setDescription(res.data.description);
          setLevelid(res.data.level.id);
          // setEndtime(res.data.end_time);
          // setFrequency(res.data.frequency);
          // setCamped(res.data.is_camped);
          // setImg(res.data.image);
        })
        
      }, []);
         
      // console.log(Camped);
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

    if ("name" in fieldValues){

      if(fieldValues.name === ''){
        temp.name = "This is field is required";
        
      }else{
        
        temp.name = "";
      }
    
    }

  //   if ("start_date" in fieldValues){
      
  //     if(fieldValues.start_date == ''){
  //       temp.start_date = "This is field is required";
        
  //     }else{
        
  //       temp.start_date = "";
  //     }
      
  //   }
  //   if ("city" in fieldValues){

  //     if(fieldValues.city === ''){
  //       temp.city = "This is field is required";
        
  //     }else{
        
  //       temp.city = "";
  //     }
    
  //   }
  //   if ("location" in fieldValues){

  //     if(fieldValues.location == ''){
  //       temp.location = "This is field is required";
     
  //     }else{
        
  //       temp.location = "";
  //     }
    
  //   }
  //   if ("capacity" in fieldValues){

  //     if(fieldValues.capacity == ''){
  //       temp.capacity = "This is field is required";
     
  //     }else{
        
  //       temp.capacity = "";
  //     }
    
  //   }
  //   if ("minimum_pnumber" in fieldValues){

  //     if(fieldValues.minimum_pnumber == ''){
  //       temp.minimum_pnumber = "This is field is required";
     
  //     }else{
        
  //       temp.minimum_pnumber = "";
  //     }
    
    
  //   }
  //   if ("trip_place" in fieldValues){

  //     if(fieldValues.trip_place == ''){
  //       temp.trip_place = "This is field is required";
     
  //     }else{
        
  //       temp.trip_place = "";
  //     }
    
    
  //   }
  //   if ("longitude" in fieldValues){

  //     if(fieldValues.longitude == ''){
  //       temp.longitude = "This is field is required";
     
  //     }else{
        
  //       temp.longitude = "";
  //     }
    
    
  //   }
  //   if ("latitude" in fieldValues){

  //     if(fieldValues.latitude == ''){
  //       temp.latitude = "This is field is required";
     
  //     }else{
        
  //       temp.latitude = "";
  //     }
    
    
  //   }
  //   if ("description" in fieldValues){

  //     if(fieldValues.description == ''){
  //       temp.description = "This is field is required";
     
  //     }else{
        
  //       temp.description = "";
  //     }
    
    
  //   }
  //   if ("level_id" in fieldValues){

  //     if(fieldValues.level_id == ''){
  //       temp.level_id = "This is field is required";
     
  //     }else{
        
  //       temp.level_id = "";
  //     }
    
    
  //   }
  //   if ("end_time" in fieldValues){

  //     if(fieldValues.end_time == ''){
  //       temp.end_time = "This is field is required";
     
  //     }else{
        
  //       temp.end_time = "";
  //     }
    
    
  //   }
  //   if ("frequency" in fieldValues){

  //     if(fieldValues.frequency == ''){
  //       temp.frequency = "This is field is required";
     
  //     }else{
        
  //       temp.frequency = "";
  //     }
  //   } 
  //   if ("is_camped" in fieldValues){

  //     if(fieldValues.is_camped == ''){
  //       temp.is_camped = "This is field is required";
     
  //     }else{
        
  //       temp.is_camped = "";
  //     }
  //   }
  //   if ("image" in fieldValues){

  //     if(fieldValues.image == ''){
  //       temp.image = "This is field is required";
     
  //     }else{
        
  //       temp.image = "";
  //     }
    
    
  //   }


  //   setErrors({
  //     ...temp,
  //   });
  //   if (fieldValues == loginInput)
  //     return Object.values(temp).every((x) => x == "");
  };
  const [selectedPark, setSelectedPark] = useState(null);
  const [latitude, setlatitude] = useState();
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position)=>{
      setlatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    })
  });
  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);
  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, );


  const handleSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    const time =format(new Date(e.target.start_date.value),'yyyy-MM-dd kk:mm:ss')

    const year=time.slice(0,4);

    const month=time.slice(5,7);

    const day=time.slice(8,10);
  
    const space=time.slice(10,11);

    const hours=time.slice(11,13);
    const dot1=time.slice(13,14);
    const minute=time.slice(14,16);
    const dot2=time.slice(16,17);
    const secound=time.slice(17,19);



 
    console.log(format(new Date(e.target.start_date.value),'yyyy-MM-dd kk:mm:ss'));
    // if (validate()) {
      e.preventDefault();
      const bodyFormData = new FormData();
      bodyFormData.append("name", e.target.name.value);
      if((time.slice(time.indexOf(" ") +1)).slice(0,2) == 24){
            const formatetime1=year+"-"+month+"-"+day+space+"00"+dot1+minute+dot2+secound;
            
            bodyFormData.append("start_date",formatetime1);
          }else{
            
            const formatetime2=year+"-"+month+"-"+day+space+hours+dot1+minute+dot2+secound;
            bodyFormData.append("start_date",formatetime2);
        }
      bodyFormData.append("location", e.target.location.value);
      bodyFormData.append("capacity", e.target.capacity.value);
        bodyFormData.append("minimum_pnumber", e.target.minimum_pnumber.value);
        bodyFormData.append("trip_place", e.target.trip_place.value);
        bodyFormData.append("longitude",e.target.longitude.value);
        bodyFormData.append("latitude", e.target.latitude.value);
        bodyFormData.append("description",e.target.description.value);
        bodyFormData.append("level_id",e.target.level_id.value);
        bodyFormData.append("end_time", e.target.end_time.value);
        bodyFormData.append("frequency",e.target.frequency.value);
        bodyFormData.append("is_camped",e.target.is_camped.value);
        // bodyFormData.append("image",e.target.image.files[0]);
        bodyFormData.append("_method", "put");
        
        
        axios
          .post(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, bodyFormData,{ headers: headers })
          .then((res) => {
            if (res.status  === 200) {
              sweetalert("edit successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/trips");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              setLogin({ ...loginInput, error_list: res.data.error });
            }
          });
      // };
    }

    useEffect(() => {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }

      axios
        .get(`https://ijk.expentech.com/api/admin_panel/levels`,  { headers: headers })
        .then((res) => {
          setlevel(res.data.data);

          setIsLoading(false);
        })
        .catch((err) => {

          setlevel([]);
          setIsLoading(false);
        });
        },[]);
 
        const handleChange = (event) => {
          event.persist();
          setFormValues({ ...formValues, [event.target.name]: event.target.value});
          
        };
        const handlefile1 = (event) => {
          event.persist();
          setFormValues({ ...formValues, image:event.target.files[0]});
         
        };
        // =====================================================================


    // This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
  const file1=e.target.files[0];
  console.log(file1);
  const file2=URL.createObjectURL((file1))
  
  setUpdateimage(file1);
  console.log(Updateimage);
        }
    };
 const styles = {
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };
// =====================================================================
  return (
    <div className="main-div">
      <h3 className="titlepage">Update Trip</h3>
      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>
        {/* <form>
          <label for="appt-time">Choose an appointment time: </label>
          <input id="appt-time" type="time" name="appt-time" step="2" />
        </form> */}

          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Trip Name"
                className="form-control"
                label="Trip Name"
                name="name"
                fullWidth
                required
                value={formValues.name.value}
                type="text"
                onChange={handleChange}
              // error={errors.name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                className="form-control"
                name="start_date"
                fullWidth
                required
                type="datetime-local"
              onChange={handleChange}
              error={errors.start_date}
              value={formValues.start_date.value}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {/* <Controls.Input
                className="form-control"
                name="end_time"
                fullWidth
                required
                type="time"
                step="2"
                // value={formValues.end_time.value}
              onChange={handleChange}
              error={errors.end_time}
              id="appt-time" 

            /> */}
            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">

            <input className="MuiInputBase-input MuiOutlinedInput-input form-control" id="appt-time" type="time" name="end_time" step="2" onChange={handleChange} fullWidth value={formValues.end_time.value} />
            <fieldset  class="PrivateNotchedOutline-root-2 MuiOutlinedInput-notchedOutline">

            </fieldset>
            </div>
          </Grid>
     
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="City"
                className="form-control"
                label="City"
                name="location"
                fullWidth
                required
                type="text"
                value={formValues.location.value}
              onChange={handleChange}
              error={errors.location}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Capacity"
                className="form-control"
                label="Capacity"
                name="capacity"
                fullWidth
                required
                type="text"
                value={formValues.capacity.value}
              onChange={handleChange}
              error={errors.capacity}
            />
            </Grid>
            <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Frequency"
                className="form-control"
                name="frequency"
                fullWidth
                required
                type="text"
              onChange={handleChange}
              value={formValues.frequency.value}  
              error={errors.frequency}
            />
          </Grid>
            <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="The Minimum Capacity"
                className="form-control"
                label="The Minimum Capacity"
                name="minimum_pnumber"
                fullWidth
                required
                type="text"
                value={formValues.minimum_pnumber.value}    
                onChange={handleChange}
                error={errors.minimum_pnumber}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Area"
                className="form-control"
                label="Area"
                name="trip_place"
                fullWidth
                required
                type="text"
                value={formValues.trip_place.value}    
                onChange={handleChange}
              error={errors.trip_place}
            />
          </Grid>
          <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 
                 name="is_camped"
                  onChange={handleChange}>
                  {formValues.is_camped.value === 1  ? (
                    <option selected disabled value={formValues.is_camped.value}>Yes</option>
                  ) : (
                    <option selected disabled value={formValues.is_camped.value}>No</option>
                  )}
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
             
              </div>
       

          </Grid>
         
          <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="level_id"
                  onChange={handleChange}
                  >
                  <option value={formValues.level_id.value} selected disabled>{formValues.level_name.value}</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts.id}>
                      {opts.name}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
               
              </div>
       

          </Grid>

          {/* <div>{formValues.image.value}</div> */}
          <Grid item md={12} xs={12}>
            <Controls.Input
                className="form-control"
                type="file"
                name="image"
                // value={selectedImage2}
                fullWidth
              onChange={handlefile1}
              // error={errors.image}
              // value={formValues/.image.value}
            />
          </Grid>
          <Grid item md={12} xs={12}>
           {Updateimage && (
          <div style={styles.preview}>

            <img
              src={Updateimage}
              style={styles.image}
              alt="Thumb"
            />
{/*             <button onClick={removeSelectedImage} style={styles.delete}>
              Remove This Image
            </button> */}
          </div>
        )}
          </Grid>
          <Grid item md={12} xs={12}>
          <JoditEditor
			      ref={editor}
            value={formValues.description.value}
            name="description"
            onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
			      onChange={newContent => {}}
          />
          </Grid>

          <Grid item md={12} xs={12}>        
            <MyComponent 
           
            />
          </Grid>
          <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
          <Controls.Input
                className="form-control"
                fullWidth
                type="text"
                value={formValues.latitude.value}
                name="latitude"
                onChange={handleChange}
                disabled
            />
          </Grid>
          <Grid item md={6} xs={12}>
          <Controls.Input
                className="form-control"
                fullWidth
                type="text"
                value={formValues.longitude.value}
                disabled
                name="longitude"
                 onChange={handleChange}
            />
          </Grid>
          </Grid>
          </Grid>
         
        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
      {/* <App /> */}
    </div>
  );
}
// const App = () => {
//   const [message, setMessage] = useState('Initial value');

//   // 👇️ called every time input's value changes
//   const handleChange11 = event => {
//     setMessage(event.target.value);
//   };

//   console.log(message);

//   return (
//     <div>
//       <input
//         id="message"
//         name="message"
//         type="text"
//         onChange={handleChange11}
//         value={message}
//       />
//     </div>
//   );
// };
