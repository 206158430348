import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 25.196121349827493,
   lng: 55.27173801039693
};

function MyComponent() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDeGRm_sYdTH7W5dgRe9UsdSRqqrGBS5nE"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={(e) => {
          let latitude=[]
          let longtitude=[]
          latitude.push(e.latLng.lat());
          longtitude.push(e.latLng.lng());
        
          
          localStorage.setItem("latitude", latitude);
          localStorage.setItem("longtitude", longtitude);

      }}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyComponent)