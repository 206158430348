import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import React, { useEffect, useState } from "react";
import { getAllMarshalls } from "../../../services";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ImageIcon from "@mui/icons-material/Image";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../global/ConfirmDialog";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import Tooltip from "@mui/material/Tooltip";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { randomId } from "@mui/x-data-grid-generator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as sweetalert from "sweetalert";
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {};

  return (
    <>
      <GridToolbarContainer>
        <Box className="div-btn-cancel">
          <Button
            component={Link}
            variant="contained"
            className="btn-view"
            color="secondary"
            // onClick={handleClick}
            to="/add"
          >
            <AddIcon style={{ color: "white", fontSize: "15px" }} />
            Add Record{" "}
          </Button>
        </Box>
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Marshalls = () => {
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const [marshalls, setMarshalls] = useState([]);
  const [image, setImages] = useState([]);
  const [carImage, setCarImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState();
  const [fullname, setFullname] = useState([]);
  const [username, setUsername] = useState([]);
  const [Image, setImage] = useState([]);
  const [phonenumber, setPhonenumber] = useState([]);
  const [countrycode, setCountrycode] = useState([]);
  const [email, setEmail] = useState([]);
  const [Whatsapplink, setWhatsapplink] = useState([]);
  const [FacebookLink, setFacebookLink] = useState([]);
  const [InstgramLink, setInstgramLink] = useState([]);
  const [Point, setPoint] = useState([]);
  const [userlevel, setUserlevel] = useState([]);
  const [Countrycode, setCountrycod] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);
  const [level, setlevel] = useState([]);
  const [Password, setPassword] = useState([]);
  const [lastDeletedTitle, setLastDeletedTitle] = useState("");

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    getAllMarshalls()
      .then((res) => {
        setMarshalls(res.data);
        setImages(res.data);
        // console.log(image);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setMarshalls([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  const handleDeleteClick1 = (user_id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    getAllMarshalls().then((res) => {
      console.log(res);
      setFullname(res.data.data.full_name);
      setUsername(res.data.data.username);
      setImage(res.data.data.image);
      setPhonenumber(res.data.data.phone_number);
      setCountrycod(res.data.data.country_code);
      setEmail(res.data.data.email);
      setWhatsapplink(res.data.data.whatsapp_link);
      setFacebookLink(res.data.data.facebook_link);
      setInstgramLink(res.data.data.instgram_link);
      setPassword(res.data.data.password);
      setPoint(res.data.data.points);
      setUserlevel(res.data.data.level.name);
      console.log(Image, "ffff");
    });

    const bodyFormData = new FormData();
    bodyFormData.append("full_name", fullname);
    bodyFormData.append("username", username);
    bodyFormData.append("phone_number", phonenumber);
    bodyFormData.append("country_code", Countrycode);
    bodyFormData.append("email", email);
    bodyFormData.append("level_id", userlevel);
    bodyFormData.append("image", Image);
    bodyFormData.append("whatsapp_link", Whatsapplink);
    bodyFormData.append("facebook_link", FacebookLink);
    bodyFormData.append("instgram_link", InstgramLink);
    bodyFormData.append("password", Password);
    bodyFormData.append("points", Point);

    axios
      .get(
        `https://ijk.expentech.com/api/admin_panel/upgrade_user/${user_id}`,
        { headers: headers },
        bodyFormData
      )
      .then((res) => {
        if (res.data.code === 200) {
          setLastDeletedTitle(res);
        } else {
          sweetalert("not found user id", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      })
      .catch((err) => {});
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  useEffect(() => {
    if (lastDeletedTitle !== "") {
      sweetalert("Rank downgraded to user", {
        className: "alert-div",
        button: {
          text: "ok",
        },
      });
      getAllMarshalls().then((res) => {
        setMarshalls(res.data);
      });
    }
  }, [lastDeletedTitle]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    navigate(`/edit/${id}`, { state: { id: id } });
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .delete(`https://ijk.expentech.com/api/admin_panel/marshalls/${id}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          sweetalert("deleted successfully", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
          setMarshalls(marshalls.filter((row) => row.id !== id));
        } else {
          sweetalert("not found user id", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      })
      .catch((err) => {});
  };
  const handleViewClick = (id) => () => {
    navigate(`/viewMarshalle/${id}`, { state: { id: id } });
  };
  const handleJoinedClick = (id) => () => {
    navigate(`/joined_trips/${id}`, { state: { id: id } });
  };
  const handleCreatedClick = (id) => () => {
    console.log("id", id);
    navigate(`/created_trips/${id}`, { state: { id: id } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (id) => () => {
    setOpen(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/marshalls/${id}`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        setDataAnnouncement(res.data.data.image);
        console.log(dataAnnouncement);
        setIsLoading(false);
      });
    // setRows(rows.filter((row) => row.id !== id))
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };


  const columns = [
    {
      field: "id",
      headerName: "Marshall’s ID",
      type: "text",
      // flex: 4,
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "username",
      headerName: "Marshall’s name",
      type: "text",
      // flex: 4,
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "number",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      minWidth: 150,
      maxWidth: 500,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id.row.id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={
              <Tooltip title="View">
                <VisibilityRoundedIcon />
              </Tooltip>
            }
            label="View"
            onClick={handleViewClick(id.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Joined Trips">
                <DepartureBoardIcon />
              </Tooltip>
            }
            label="View"
            onClick={handleJoinedClick(id.row.user_id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Created Trips">
                <TimeToLeaveIcon />
              </Tooltip>
            }
            label="View"
            onClick={handleCreatedClick(id.row.user_id)}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={
            <Tooltip title="show Image">
              <ImageIcon />
            </Tooltip>
          }
          label="view"
          onClick={handleClickOpen(id.row.id)}
          color="inherit"
        />,
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Personal Image</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <img
                height="100"
                width="200"
                alt="med1"
                src={"https://ijk.expentech.com/storage/" + dataAnnouncement}
              />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>,
          <GridActionsCellItem
            icon={
              <Tooltip title="Marshall to user">
                <JoinRightIcon />
              </Tooltip>
            }
            label="view"
            color="inherit"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure about this procedure?",
                onConfirm: () => {
                  handleDeleteClick1(id.row.user_id);
                },
              });
            }}
          />,
      
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            color="inherit"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure about this procedure?",
                onConfirm: () => {
                  handleDeleteClick(id.row.id);
                },
              });
            }}
          />,
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />,
        ];
      },
    },
  ];

  return (
    <div className="main-div">
      <Header title="Marshalls" />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box></Box>
        </Box>

        <Box
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.redAccent[500],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[700],
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.grey[600]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGridPro
            rows={marshalls}
            columns={columns}
            editMode="row"
            pageSize={5}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Marshalls;
