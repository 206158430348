import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  // plugins: {
  //   legend: {
  //     position: "right",
  //   },
  //   title: {
  //     display: true,
  //     text: "Chart.js Horizontal Bar Chart",
  //   },
  // },
};

const Horizontalchart = () => {
  const [data, setData] = useState({
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "Coun Of Trips",
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
      },
      // {
      //   label: "Dataset 2",
      //   data: [],
      //   borderColor: "rgb(53, 162, 235)",
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
  });
  useEffect(() => {
    const fetchData = async () => {
      //  const url = 'https://jsonplaceholder.typicode.com/comments'
      const labelSet = [];
      const dataSet1 = [];
      const dataSet2 = [];
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }
      await fetch(`https://ijk.expentech.com/api/admin_panel/dashboard`, {
        headers: headers,
      })
        .then((data) => {
          console.log("Api data", data);
          const res = data.json();
          return res;
        })
        .then((res) => {
         
          for (const val of res.data.levels) {
            dataSet1.push(val);
          }
          for (const val of res.data.levelsCounts) {
            dataSet2.push(val);
          }
          
          setData({
            labels: dataSet1,
              
            
            datasets: [
              {
                label: "Coun Of Trips",
                data: dataSet2,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
              },
              // {
              //   label: "Dataset ID2",
              //   data: dataSet2,
              //   borderColor: "rgb(53, 162, 235)",
              //   backgroundColor: "rgba(53, 235, 0.5)",
              // },
            ],
          });
          console.log("arrData", dataSet1, dataSet2);
        })
        .catch((e) => {
          console.log("error", e);
        });
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: "80%", height: "50%" }}>
      {console.log("dataaaaaaaa", data)}
      <Bar data={data} options={options} />
    </div>
  );
};
export default Horizontalchart;
