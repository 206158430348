import React, { useState, useEffect,useRef, useMemo } from "react";
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm, Form } from "./useForm1";
import Controls from "../global/controls/Controls";
import { getAllLevel } from "../../../services";
import {Grid,FormControl} from "@material-ui/core";
import MyComponent from '../Google/google'
import JoditEditor from "jodit-react"
import { positions } from "@mui/system";
import { format } from 'date-fns';
export default function Createnewtrip() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [level, setlevel] = useState([]);
  const [levels,setlevels] = useState([]);
  const [camped,setcamped] = useState([]);
  const [desc,setDesc] = useState('');
  const lang='';
  const initialFValues = {
    name: "",
    start_date: "",
    location: "",
    capacity: "",
    minimum_pnumber: "",
    trip_place: "",
    longitude: "",
    latitude: "",
    description: "",
    level_id: "",
    end_time: "",
    frequency: "",
    is_camped:" ",
    image:" ",
  };
  const validate = (fieldValues = loginInput) => {
  //   let temp = { ...errors };

  //   if ("name" in fieldValues){

  //     if(fieldValues.name === ''){
  //       temp.name = "This is field is required";
        
  //     }else{
        
  //       temp.name = "";
  //     }
    
  //   }

  //   if ("start_date" in fieldValues){
      
  //     if(fieldValues.start_date == ''){
  //       temp.start_date = "This is field is required";
        
  //     }else{
        
  //       temp.start_date = "";
  //     }
      
  //   }
  //   if ("city" in fieldValues){

  //     if(fieldValues.city === ''){
  //       temp.city = "This is field is required";
        
  //     }else{
        
  //       temp.city = "";
  //     }
    
  //   }
  //   if ("location" in fieldValues){

  //     if(fieldValues.location == ''){
  //       temp.location = "This is field is required";
     
  //     }else{
        
  //       temp.location = "";
  //     }
    
  //   }
  //   if ("capacity" in fieldValues){

  //     if(fieldValues.capacity == ''){
  //       temp.capacity = "This is field is required";
     
  //     }else{
        
  //       temp.capacity = "";
  //     }
    
  //   }
  //   if ("minimum_pnumber" in fieldValues){

  //     if(fieldValues.minimum_pnumber == ''){
  //       temp.minimum_pnumber = "This is field is required";
     
  //     }else{
        
  //       temp.minimum_pnumber = "";
  //     }
    
    
  //   }
  //   if ("trip_place" in fieldValues){

  //     if(fieldValues.trip_place == ''){
  //       temp.trip_place = "This is field is required";
     
  //     }else{
        
  //       temp.trip_place = "";
  //     }
    
    
  //   }
  //   if ("longitude" in fieldValues){

  //     if(fieldValues.longitude == ''){
  //       temp.longitude = "This is field is required";
     
  //     }else{
        
  //       temp.longitude = "";
  //     }
    
    
  //   }
  //   if ("latitude" in fieldValues){

  //     if(fieldValues.latitude == ''){
  //       temp.latitude = "This is field is required";
     
  //     }else{
        
  //       temp.latitude = "";
  //     }
    
    
  //   }
  //   if ("description" in fieldValues){

  //     if(fieldValues.description == ''){
  //       temp.description = "This is field is required";
     
  //     }else{
        
  //       temp.description = "";
  //     }
    
    
  //   }
  //   if ("level_id" in fieldValues){

  //     if(fieldValues.level_id == ''){
  //       temp.level_id = "This is field is required";
     
  //     }else{
        
  //       temp.level_id = "";
  //     }
    
    
  //   }
  //   if ("end_time" in fieldValues){

  //     if(fieldValues.end_time == ''){
  //       temp.end_time = "This is field is required";
     
  //     }else{
        
  //       temp.end_time = "";
  //     }
    
    
  //   }
  //   if ("frequency" in fieldValues){

  //     if(fieldValues.frequency == ''){
  //       temp.frequency = "This is field is required";
     
  //     }else{
        
  //       temp.frequency = "";
  //     }
  //   } 
  //   if ("is_camped" in fieldValues){

  //     if(fieldValues.is_camped == ''){
  //       temp.is_camped = "This is field is required";
     
  //     }else{
        
  //       temp.is_camped = "";
  //     }
  //   }
  //   if ("image" in fieldValues){

  //     if(fieldValues.image == ''){
  //       temp.image = "This is field is required";
     
  //     }else{
        
  //       temp.image = "";
  //     }
    
    
  //   }


  //   setErrors({
  //     ...temp,
  //   });
  //   if (fieldValues == loginInput)
  //     return Object.values(temp).every((x) => x == "");
  };
  const [selectedPark, setSelectedPark] = useState(null);
  const [latitude, setlatitude] = useState();
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position)=>{
      setlatitude(position.coords.latitude)
      setLongitude(position.coords.longitude)
    })
  });
  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);
  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handlefile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, );

  const handleSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    
    // if (validate()) {
     
      // moment(yourDate).format("YYYY/MM/DD kk:mm:ss");
      console.log(format(new Date(loginInput.start_date), 'yyyy/MM/dd kk:mm:ss'));
     
        e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("name", loginInput.name);
        bodyFormData.append("start_date", format(new Date(loginInput.start_date), 'yyyy-MM-dd kk:mm:ss'));
        bodyFormData.append("location", loginInput.location);
        bodyFormData.append("capacity", loginInput.capacity);
        bodyFormData.append("minimum_pnumber", loginInput.minimum_pnumber);
        bodyFormData.append("trip_place", loginInput.trip_place);
        bodyFormData.append("longitude",localStorage.getItem("longtitude"));
        bodyFormData.append("latitude", localStorage.getItem("latitude"));
        bodyFormData.append("description",desc);
        bodyFormData.append("level_id",levels);
        bodyFormData.append("end_time", loginInput.end_time);
        bodyFormData.append("frequency", loginInput.frequency);
        bodyFormData.append("is_camped",camped);
        bodyFormData.append("image", e.target.image.files[0]);
        console.log(bodyFormData);
        axios
          .post(`https://ijk.expentech.com/api/admin_panel/trips`, bodyFormData,{ headers: headers })
          .then((res) => {
            if (res.status  === 200) {
              sweetalert("Added successfully", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              navigate("/trips");
            } else {
              sweetalert("Error some thing", {
                className: "alert-div",
                button: {
                  text: "ok",
                },
              });
              setLogin({ ...loginInput, error_list: res.data.error });
            }
          });
      };
    // }
      
    useEffect(() => {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }

      axios
        .get(`https://ijk.expentech.com/api/admin_panel/levels`,  { headers: headers })
        .then((res) => {
          setlevel(res.data.data);
          // console.log(res.data.data)
          setIsLoading(false);
        })
        .catch((err) => {

          setlevel([]);
          setIsLoading(false);
        });
        },[]);
 
// ==============================================
   const [selectedImage, setSelectedImage] = useState();
 
    // This function will be triggered when the file field change
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setSelectedImage(e.target.files[0]);
        }
    };


 // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
          setSelectedImage();
            
      };
   const styles = {
      preview: {
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
      },
      image: { maxWidth: "100%", maxHeight: 320 },
      delete: {
        cursor: "pointer",
        padding: 15,
        background: "red",
        color: "white",
        border: "none",
      },
    };
// ==============================================
  return (
    <div className="main-div">
      <h3 className="titlepage">Add Trip</h3>
      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Trip Name"
                className="form-control"
                label="Trip Name"
                name="name"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                className="form-control"
                name="start_date"
                fullWidth
                required
                type="datetime-local"
              onChange={handleInputChange}
              error={errors.start_date}
            
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {/* <Controls.Input
                className="form-control"
                name="end_time"
                fullWidth
                required
                type="time"
                step="2"
                format="H:i:s"
                onChange={handleInputChange}
                error={errors.end_time}
            /> */}
            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">

            <input className="MuiInputBase-input MuiOutlinedInput-input form-control" id="appt-time" type="time" name="end_time" step="2" onChange={handleInputChange} />
            <fieldset  class="PrivateNotchedOutline-root-2 MuiOutlinedInput-notchedOutline">

            </fieldset>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="City"
                className="form-control"
                label="City"
                name="location"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.location}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Capacity"
                className="form-control"
                label="Capacity"
                name="capacity"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.capacity}
            />
            </Grid>
            <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Frequency"
                className="form-control"
                label="Frequency"
                name="frequency"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.frequency}
            />
          </Grid>
            <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="The Minimum Capacity"
                className="form-control"
                label="The Minimum Capacity"
                name="minimum_pnumber"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.minimum_pnumber}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
                placeholder="Area"
                className="form-control"
                label="Area"
                name="trip_place"
                fullWidth
                required
                type="text"
              onChange={handleInputChange}
              error={errors.trip_place}
            />
          </Grid>
          

          <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="level_id"
                  onChange={(e) => setlevels(e.target.value)}
                  >
                    <option selected disabled>Chooes option</option>
                  {level.map((opts,i) => (
                    <option key={i} value={opts.id}>
                      {opts.name}
                    </option>
                  ))}
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">Level</label>
              </div>
       

          </Grid>

    
          <Grid item md={6} xs={12}>
              <div class="select">
                <select 
                class="select-text"
                 required
                 name="is_camped"
                onChange={(e) =>setcamped(e.target.value)}>
                 <option selected disabled>Chooes option</option>
                  <option value='1'>Yes</option>
                  <option value='0'>No</option>
                </select>
                <span class="select-highlight"></span>
                <span class="select-bar"></span>
                <label class="select-label">Camped</label>
              </div>
       

          </Grid>

          <Grid item md={12} xs={12}>
            <Controls.Input
                className="form-control"
                name="image"
                fullWidth
                required
                type="file"
              // onChange={handlefile}
              error={errors.image}
              onChange={imageChange} 
              accept="image/*"
            />
          </Grid>

          <Grid item md={12} xs={12}>
           {selectedImage && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage)}
              style={styles.image}
              alt="Thumb"
            />
          {/* {URL.createObjectURL(selectedImage)} */}
{/*             <button onClick={removeSelectedImage} style={styles.delete}>
              Remove This Image
            </button> */}
          </div>
        )}
          </Grid>
          <Grid item md={12} xs={12}>
          <JoditEditor
			      ref={editor}
            name="description"
            onBlur={newContent => setDesc(newContent)} // preferred to use only this option to update the content for performance reasons
			      onChange={newContent => {}}
            
          />
          </Grid>

          <Grid item md={12} xs={12}>        
            <MyComponent 
           
            />
   
          </Grid>
          </Grid>
          <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
          <Controls.Input
                className="form-control"
                fullWidth
                label="latitude"
                type="text"
                value={'25.196121349827493'}
                disabled

            />
          </Grid>
          <Grid item md={6} xs={12}>
          <Controls.Input
                className="form-control"
                fullWidth
                label="longitude"
                type="text"
                value={'55.27173801039693'}
                disabled
            />
          </Grid>
          </Grid>
        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
    </div>
  );
}
