import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import React, { useEffect, useState } from "react";
import { getAllMarshalls } from "../../../services";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ImageIcon from "@mui/icons-material/Image";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../global/ConfirmDialog";
import GroupsIcon from "@mui/icons-material/Groups";
import Tooltip from "@mui/material/Tooltip";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { randomId } from "@mui/x-data-grid-generator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as sweetalert from "sweetalert";
import { useParams } from "react-router-dom";
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {};

  return (
    <>
      <GridToolbarContainer>
        <Box className="div-btn-cancel">
          <Button
            component={Link}
            variant="contained"
            className="btn-view"
            color="secondary"
            to="/Add-new-trip"
          >
            <AddIcon style={{ color: "white", fontSize: "15px" }} />
            Add Record
          </Button>
        </Box>
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const CreatedSideBar = () => {
  const userid = localStorage.getItem("userid");
  const [joinedTrips, setJoinedTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState();
  const [levelname, setLevelname] = useState([]);
  const [marshalllsname, setMarshalllsname] = useState([]);
  const [levels, setlevels] = useState([]);
  const { id } = useParams();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);

    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/created_trip/${userid}`, {
        headers: headers,
      })
      .then((res) => {
        setJoinedTrips(res.data.data);
        setLevelname(levels.map((e) => e.name));

        setIsLoading(false);
      })
      .catch((err) => {
        setJoinedTrips([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    navigate(`/edit/${id}`, { state: { id: id } });
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }

    axios
      .delete(`https://ijk.expentech.com/api/admin_panel/trips/${id}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          sweetalert("okت العملية بنجاح", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
          setJoinedTrips(joinedTrips.filter((row) => row.id !== id));
        } else {
          sweetalert("هذا المستخدم غير موجود", {
            className: "alert-div",
            button: {
              text: "ok",
            },
          });
        }
      })
      .catch((err) => {});
  };
  const handleViewClick = (id) => () => {
    navigate(`/view-trip/${id}`, { state: { id: id } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Trip",
      cellClassName: "name-column--cell",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },

    {
      field: "location",
      headerName: "City",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "trip_place",
      headerName: "Area",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
    },
    {
      field: "is_camped",
      headerName: "Camping",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueGetter: (params) => {
        if (params.row.is_camped == 1) {
          return "Yes";
        } else {
          return "no";
        }
      },
    },
    {
      field: { levelname },
      headerName: "Level",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueGetter: (params) => {
        return params.row.level.name;
      },
    },
    {
      field: "",
      headerName: "Marshall",
      width: 250,
      minWidth: 150,
      maxWidth: 200,
      editable: true,
      valueGetter: (params) => {
        return params.row.marshall.user.username;
      },
    },
    {
      field: "attend",
      headerName: "Attend",
      type: "text",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      minWidth: 150,
      maxWidth: 500,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => {
              navigate(`/update-trip/${id}`, { state: { id: id } });
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            color="inherit"
            onClick={() => {
              console.log("12");
              setConfirmDialog({
                isOpen: true,
                title: "هل تريد حذف هذه الرحلة؟",
                subTitle: "لايمكن التراجع عن هذه العملية",
                onConfirm: () => {
                  handleDeleteClick(id);
                },
              });
            }}
          />,

          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="View">
                <VisibilityRoundedIcon />
              </Tooltip>
            }
            label="View"
            onClick={handleViewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Participants">
                <GroupsIcon />
              </Tooltip>
            }
            label="view"
            color="inherit"
            onClick={() => {
              navigate(`/trip_users/${id}`, { state: { id: id } });
            }}
          />,
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Personal Image</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <img height="100" width="200" alt="med1" src="" />
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>,
        ];
      },
    },
  ];

  return (
    <div className="main-div">
      <Header title="Created Trips" />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box></Box>
        </Box>

        <Box
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.redAccent[500],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[700],
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.grey[600]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGridPro
            rows={joinedTrips}
            columns={columns}
            editMode="row"
            pageSize={5}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CreatedSideBar;
