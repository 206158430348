import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/scenes/auth/Login";
import {ResetPassword} from "../pages/scenes/auth/reset_password";
import Dashboard from "../pages/scenes/dashboard";
import Marshalls from "../pages/scenes/marshalls";
import AddOrEdit from "../pages/scenes/marshalls/addOrEdit";
import { AuthProvider } from "../navigation/Auth/auth";
import { RequireAuth } from "../navigation/Auth/RequireAuth";
import AddMarshalle from "../pages/scenes/marshalls/AddMarshalle";
import ViewMarshalle from "../pages/scenes/marshalls/ViewMarshalle";
import ViewCreated from "../pages/scenes/createdTrips/ViewCeated";
import FullFeaturedCrudGrid from "../pages/scenes/marshalls/dialogs";
import Trips from "../pages/scenes/trips";
import Createnewtrip from "../pages/scenes/trips/Add";
import My_Trips from "../pages/scenes/my_trips";
import Joined_Trips from "../pages/scenes/joinedTrips";
import IndexSideBar from "../pages/scenes/joinedTrips/indexSideBar.jsx";
import CreatedTrips from "../pages/scenes/createdTrips";
import CreatedSideBar from "../pages/scenes/createdTrips/createdSideBar.jsx";
import ViewPrfile from "../pages/scenes/profile/ViewProfle";
import User from "../pages/scenes/user";
import Updateuser from "../pages/scenes/user/update";
import UpdateLevelUser from "../pages/scenes/user/updateLeveluser";
import Createnewuser from "../pages/scenes/user/Add";
import ViewUserbyid from "../pages/scenes/user/Viewuser";
import ViewTripbyid from "../pages/scenes/trips/Viewtrip";
import Updatetrip from "../pages/scenes/trips/Updatetrip";
import Participants from "../pages/scenes/trips/participants";
import Addmembertrip from "../pages/scenes/trips/addmembertrip";
import Level from "../pages/scenes/level";
import Announcements from "../pages/scenes/announcements";
import AddAnnouncement from "../pages/scenes/announcements/AddAnnouncement";
import Profile from "../pages/scenes/profile";
import EditProile from "../pages/scenes/profile/EditProile";
import Siteinfo from "../pages/scenes/siteinfo";
import { NotFound } from "../navigation/NotFound";

import { DASHBOARD, MARSHALLS, TRIPS,MY_TRIPS,USER ,JOINED_TRIPS,CREATED_TRIPS,LEVEL,ANNOUNCEMENTS,PROFILE,SITEINFO,ADDMARSALLS,LOGIN} from "../navigation/CONSTANTS";


 const RouterConfig = () => {
  return (
    
    <>
    {/* <Outlet /> */}
    <AuthProvider>
     <Routes>
         
       
        {/* List all public routes here */}
        <Route exact path={LOGIN} element={<Login />} />
        
        <Route exact path={DASHBOARD}  element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            } />
        <Route exact path='/marshalls' element={<RequireAuth><Marshalls /></RequireAuth>} />
        <Route exact path='/add' element={<RequireAuth><AddMarshalle /></RequireAuth>} />
        {/* <Route exact path='/add' element={<AddOrEdit />} /> */}
        <Route exact path='/edit/:id' element={<RequireAuth><AddOrEdit /></RequireAuth>} />
               <Route exact path='/raghad' element={<RequireAuth><FullFeaturedCrudGrid /></RequireAuth>} />
        
        <Route exact path={TRIPS} element={<RequireAuth><Trips /></RequireAuth>} />
        <Route exact path={MY_TRIPS} element={<RequireAuth><My_Trips /></RequireAuth>} />
        <Route exact path={JOINED_TRIPS} element={<RequireAuth><IndexSideBar /></RequireAuth>} />
        <Route exact path={CREATED_TRIPS} element={<RequireAuth><CreatedSideBar /></RequireAuth>} />
        <Route exact path='/created_trips/:id' element={<RequireAuth><CreatedTrips /></RequireAuth>} />
        <Route exact path='/joined_trips/:id' element={<RequireAuth><Joined_Trips /></RequireAuth>} />
        <Route exact path='/addAnnouncement' element={<RequireAuth><AddAnnouncement /></RequireAuth>} />
        <Route exact path='/editProfile/:id' element={<RequireAuth><EditProile /></RequireAuth>} />
        
        <Route exact path={USER} element={<RequireAuth><User /></RequireAuth>} />
        <Route exact path={LEVEL} element={<RequireAuth><Level /></RequireAuth>} />
        <Route exact path={ANNOUNCEMENTS} element={<RequireAuth><Announcements /></RequireAuth>} />
        <Route exact path='/announcements' element={<RequireAuth><Announcements /></RequireAuth>} />
        <Route exact path={PROFILE} element={<RequireAuth><Profile /></RequireAuth>} />
        <Route exact path={SITEINFO} element={<RequireAuth><Siteinfo /></RequireAuth>} />
        <Route
            exact
            path="/view-user/:id"
            element={
              <RequireAuth><ViewUserbyid /></RequireAuth>
            }
          />
           <Route
            exact
            path="/viewmarshalle/:id"
            element={
              <RequireAuth> <ViewMarshalle /></RequireAuth>
            }
          />
           <Route
            exact
            path="/ViewPrfile/:id"
            element={
              <RequireAuth> <ViewPrfile /></RequireAuth>
            }
          />

           <Route
            exact
            path="/viewCreated/:id"
            element={
              <RequireAuth><ViewCreated /></RequireAuth>
            }
          />

        <Route
            exact
            path="/Add-new"
            element={
              <RequireAuth> <Createnewuser /></RequireAuth>
            }
          />
        <Route
            exact
            path="update-user/:id"
            element={
              <RequireAuth><Updateuser /></RequireAuth>
            }
          />
        <Route
            exact
            path="update_user_trip_level/:id"
            element={
              <RequireAuth><UpdateLevelUser /></RequireAuth>
            }
          />
        <Route
            exact
            path="/Add-new-trip"
            element={
              <RequireAuth><Createnewtrip /></RequireAuth>
            }
          />
        <Route
            exact
            path="/view-trip/:id"
            element={
              <RequireAuth><ViewTripbyid/></RequireAuth>
            }
          />
        <Route
            exact
            path="/update-trip/:id"
            element={
              <RequireAuth><Updatetrip/></RequireAuth>
            }
          />
        <Route
            exact
            path="/trip_users/:id"
            element={
              <RequireAuth> <Participants/></RequireAuth>
            }
          />
        <Route
            exact
            path="/Add-member-trip/:id"
            element={
              <RequireAuth> <Addmembertrip/></RequireAuth>
            }
          />
       
     
        <Route
            exact
            path="reset-password"
            element={
              <RequireAuth> <ResetPassword/></RequireAuth>
            }
          />

        <Route path="*">
      
         
        </Route>
        
        </Routes >
        </AuthProvider>
    </>
  );
};

export default RouterConfig;
