
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../styles/theme";
import Header from "../../../components/Header";
import { getAllTrips } from "../../../services";
import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { randomId } from "@mui/x-data-grid-generator";

const initialRows = [
  {
    id: 1,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 2,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 3,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 4,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 5,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 6,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 7,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 8,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 9,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 10,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
  {
    id: 11,
    Trip: "Jon Snow",
    Startdate: "jonsnow@gmail.com",
    image: 35,
    city: "New York",
    Area: "New York",
    Comping: "10001",
    Level: 123512,
    Marshall: 123512,
  },
];
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        Trip: "",
        Startdate: "",
        image: "",
        city: "",
        Area: "",
        Comping: "",
        Level: "",
        Marshall: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <>
      <GridToolbarContainer>
        <Box>
          <Button className="addNew" onClick={handleClick}>
            <AddIcon style={{ color: "red", fontSize: "30px" }} />
            <span className="button-color">Add Record</span>
          </Button>
        </Box>
      </GridToolbarContainer>

      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}
EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const MyTrips = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState(initialRows);

  useEffect(() => {
    setIsLoading(true);
    getAllTrips()
      .then((res) => {
        console.log("Dashboard > getAllUsers > res=", res);
        setUsers(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setUsers([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    // { field: "id", headerName: "ID", flex: 0.5 },

    {
      field: "Trip",
      headerName: "Trip",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      cellClassName: "name-column--cell",
      editable: true
    },
    {
      field: "Startdate",
      headerName: "Start Date",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "image",
      headerName: "Image",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "city",
      headerName: "City",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "Area",
      headerName: "Area",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "Comping",
      headerName: "Camping",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "Level",
      headerName: "Level",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "Marshall",
      headerName: "Marshall",
      // flex: 1,
      width: 250, minWidth: 150, maxWidth: 200 ,
      editable: true
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250, minWidth: 150, maxWidth: 200 ,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Box m="2px 5px 0px 5px">
      <Header
        title="My Trips"
        // subtitle="List of Contacts for Future Reference"
      />

<Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.grey[600]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGridPro
               
               rows={rows}
               columns={columns}
               editMode="row"
               pageSize={5}
               rowModesModel={rowModesModel}
               onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
               onRowEditStart={handleRowEditStart}
               onRowEditStop={handleRowEditStop}
               processRowUpdate={processRowUpdate}
             
               components={{
                 
                 Toolbar: EditToolbar  
               }}
              
               componentsProps={{
                 toolbar: { setRows, setRowModesModel },
                  
               }}
               experimentalFeatures={{ newEditingApi: true 
               }}
               />
      </Box>
    </Box>
  );
};

export default MyTrips;
