import React, { useState, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/material";
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getFundtype } from "../../../services";
import { getAllfund } from "../../../services";
import { useForm, Form } from "./useForm1";
import Cookies from "js-cookie";
import Controls from "../global/controls/Controls";
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
export default function AddAnnouncement() {
  const navigate = useNavigate();
  // const [fund, setfund] = useState([]);
  const [Country_code, setCountry_code] = useState([]);
  const [Newcountrycode, setNewcountrycode] = useState([]);



  const initialFValues = {
    text: "",
    date: "",
    image: "",
  };
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

    if ("text" in fieldValues) {

      if (fieldValues.text == '') {
        temp.text = "This is field is required";

      } else {

        temp.text = "";
      }

    }
    if ("date" in fieldValues) {

      if (fieldValues.date == '') {
        temp.date = "This is field is required";

      } else {

        temp.date = "";
      }

    }
    if ("image" in fieldValues) {

      if (fieldValues.image == '') {
        temp.image = "This is field is required";

      } else {

        temp.image = "";
      }

    }
   
   
   
   
   
    
   


    setErrors({
      ...temp,
    });
    if (fieldValues == loginInput)
      return Object.values(temp).every((x) => x == "");
  };

  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handleInputfile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    // if (validate()) {
      e.preventDefault();
      const bodyFormData = new FormData();
      bodyFormData.append("text", loginInput.text);
      bodyFormData.append("date", loginInput.date);
      bodyFormData.append("image", loginInput.image);
 
      axios
        .post(`https://ijk.expentech.com/api/admin_panel/announcements`, bodyFormData, { headers: headers })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            sweetalert("Added successfully", {
              className: "alert-div",
              button: {
                text: "ok",
              },
            });
            navigate('/announcements');
          } else {
            sweetalert("Error in adding", {
              className: "alert-div",
              button: {
                text: "ok",
              },
            });
            setLogin({ ...loginInput, error_list: res.data.error });
          }
        });
    // };
  }




  return (
    <div className="main-div">
      <h3 className="titlepage">Add an announcement</h3>

      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="Announcement Title"
              className="form-control"
              name="text"
              fullWidth
              required
              type="text"
              onChange={handleInputChange}
              error={errors.text}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="Announcement Details"
              className="form-control"
              name="date"
              fullWidth
              required
              type="datetime-local"
              onChange={handleInputChange}
              error={errors.date}
            />
          </Grid>

         
        
          
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="Announcement image"
              className="form-control"
              label=""
              name="image"
              fullWidth
              required
              type="file"
              onChange={handleInputfile}
              error={errors.image}
            />
          </Grid>
         
         
         

        </Grid>

        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="Submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
    </div>
  );
}
