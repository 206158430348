import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './auth'

export const RequireAuth = ({ children }) => {
  const location = useLocation()
  const auth = useAuth()
 
  // console.log(auh.user);
  // debugger;
  if (auth.user == null) {
    // return <Navigate to='/' state={{ path: location.pathname }} />
  }
  return children
}
