import React, { useState, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import * as sweetalert from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import Controls from "../global/controls/Controls";
import { useForm, Form } from "./useForm";
import validator from 'validator'
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";

export default function AddMarshalle() {
  const navigate = useNavigate();
  const [fund, setfund] = useState([]);
  const [fundpost, setFundpost] = useState([]);
  const [levels, setlevels] = useState([]);
  const [level, setlevel] = useState([]);
  const [fundoption, setFundoption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }

    axios
      .get(`https://ijk.expentech.com/api/admin_panel/get_country_codes`, {
        headers: headers,
      })
      .then((res) => {
        setlevel(res.data.data);
        console.log(res.data.full_name);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const initialFValues = {
    full_name: "",
    username: "",
    image: "",
    phone_number: "",
    country_code: "",
    points: "",
    email: "",
    whatsapp_link: "",
    facebook_link: "",
    instgram_link: "",
    car_image: "",
    car_model: "",
    car_number: "",
    car_manufacturing_year: "",
    password: "",
  };
  const validate = (fieldValues = loginInput) => {
    let temp = { ...errors };

    if ("full_name" in fieldValues)
      temp.full_name = fieldValues.full_name ? "" : "This is field is required";
    // console.log(loginInput );
    if ("username" in fieldValues)
      temp.username = fieldValues.username ? "" : "This is field is required";

    if ("phone_number" in fieldValues) {
      if (fieldValues.phone_number == "") {
        temp.phone_number = "This is field is required";
      } else if (!/[0-9]/.test(fieldValues.phone_number)) {
        temp.phone_number = "This phone number must be number";
      } else {
        temp.phone_number = "";
      }

    }

    if ("points" in fieldValues)
      temp.points = fieldValues.points ? "" : "This is field is required";

    if ("image" in fieldValues) {

      if (fieldValues.image == '') {
        temp.image = "This is field is required";

      } else {

        temp.image = "";
      }

    }
    if ("car_image" in fieldValues) {

      if (fieldValues.car_image == '') {
        temp.car_image = "This is field is required";

      } else {

        temp.car_image = "";
      }

    }



    if ("password" in fieldValues) {
      if (fieldValues.password == "") {
        temp.password = "This is field is required";
      } else if (fieldValues.password.length < 8) {
        temp.password = "too short";
      } else {
        temp.password = "";
      }


    }
    if ("email" in fieldValues) {
      if (fieldValues.email == "") {
        temp.email = "This is field is required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValues.email)) {
        temp.email = "This field is invalid";
      } else {
        temp.email = "";
      }

    }




    if ("whatsapp_link" in fieldValues) {
      if (fieldValues.whatsapp_link == "") {
        temp.whatsapp_link = "This is field is required";
      } else if (!/[0-9]/.test(fieldValues.whatsapp_link)) {
        temp.whatsapp_link = "This whatsapp link must be number";
      } else {
        temp.whatsapp_link = "";
      }

    }
    
      if ("instgram_link" in fieldValues) {
      
        if (fieldValues.instgram_link == "") {
          temp.instgram_link = "This is field is required";
        } else if (!validator.isURL(fieldValues.instgram_link)) {
          temp.instgram_link = "Is Not Valid URL";
        } else {
          temp.instgram_link = "";
        }
  
      }
     
      if ("facebook_link" in fieldValues) {
        console.log('1');
        if (fieldValues.facebook_link == "") {
          temp.facebook_link = "This is field is required";
        } else if (!validator.isURL(fieldValues.facebook_link)) {
          temp.facebook_link = "Is Not Valid URL";
        } else {
          temp.facebook_link = "";
        }
  
      }

    if ("car_model" in fieldValues) {
      if (fieldValues.car_model == "") {
        temp.car_model = "This is field is required";
      } else if (!/[0-9]/.test(fieldValues.car_model)) {
        temp.car_model = "This car model  must be number";
      } else {
        temp.car_model = "";
      }

    }
    if ("car_number" in fieldValues) {
      if (fieldValues.car_number == "") {
        temp.car_number = "This is field is required";
      } else if (!/[0-9]/.test(fieldValues.car_number)) {
        temp.car_number = "This car number  must be number";
      } else {
        temp.car_number = "";
      }

    }
    if ("car_manufacturing_year" in fieldValues) {
      if (fieldValues.car_manufacturing_year == "") {
        temp.car_manufacturing_year = "This is field is required";
      } else if (!/[0-9]/.test(fieldValues.car_manufacturing_year)) {
        temp.car_manufacturing_year = "This car manufacturing year  must be number";
      } else {
        temp.car_manufacturing_year = "";
      }

    }






    setErrors({
      ...temp,
    });
    if (fieldValues == loginInput)
      return Object.values(temp).every((x) => x == "");
  };
  const {
    loginInput,
    setLogin,
    errors,
    setErrors,
    handleInputChange,
    handlefile,
    addForm,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      let headers = {};
      if (localStorage.getItem("SavedToken") !== null) {
        headers = { Authorization: localStorage.getItem("SavedToken") };
      }

      //   const data = {
      //     mobile_number: loginInput.mobile_number,
      //     amount: loginInput.amount,
      //     trans_code: fundpost,

      //   };

      const bodyFormData = new FormData();
      bodyFormData.append("full_name", loginInput.full_name);
      bodyFormData.append("username", loginInput.username);
      bodyFormData.append("phone_number", loginInput.phone_number);
      bodyFormData.append("country_code", levels);
      bodyFormData.append("email", loginInput.email);
      bodyFormData.append("points", loginInput.points);
      if (loginInput.image == null) {
        bodyFormData.append("image", "loginInput.image");
      } else {
        bodyFormData.append("image", loginInput.image);
      }

      bodyFormData.append("whatsapp_link", loginInput.whatsapp_link);
      bodyFormData.append("facebook_link", loginInput.facebook_link);
      bodyFormData.append("instgram_link", loginInput.instgram_link);
      bodyFormData.append("password", loginInput.password);
      bodyFormData.append("car_image", loginInput.car_image);
      //   bodyFormData.append("car_image", loginInput.car_image);
      if (loginInput.car_image == null) {
        bodyFormData.append("car_image", "");
      } else {
        bodyFormData.append("car_image", loginInput.car_image);
      }
      bodyFormData.append("car_model", loginInput.car_model);
      bodyFormData.append("car_number", loginInput.car_number);
      bodyFormData.append(
        "car_manufacturing_year",
        loginInput.car_manufacturing_year
      );
      bodyFormData.append("points", loginInput.points);

      axios
        .post(
          `https://ijk.expentech.com/api/admin_panel/marshalls`,

          bodyFormData,
          { headers: headers }
        )
        .then((res) => {
          // axios.defaults.headers.common["X-CSRF-TOKEN"] = response;

          if (res.data.code == 200) {
            sweetalert("Added successfully", {
              className: "alert-div",
              button: {
                text: "ok",
              },
            });
            navigate("/marshalls");
          } else {
            sweetalert("Error ", {
              className: "alert-div",
              button: {
                text: "ok",
              },
            });
            setLogin({ ...loginInput, error_list: res.data.message });

            console.log(loginInput);
          }
        });
    }
  };

  return (
    <div className="main-div">
      <h3 className="titlepage">Add Marshalle For Trip </h3>

      <Form className="form-group" onSubmit={handleSubmit}>
        <Grid container maxWidth="sm" spacing={4}>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Full name "
              name="full_name"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.full_name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Username"
              name="username"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.username}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              placeholder="Announcement image"
              className="form-control"
              label=""
              name="image"
              fullWidth
              required
              type="file"
              onChange={handlefile}
              error={errors.image}
            />
            {/* <TextField
              placeholder="User image"
              className="form-control"
              label="User image"
              name="image"
              variant="outlined"
              fullWidth
              type="file"
              // value={formValues.image.value}
              onChange={handlefile}
            // error={formValues.image.error}
            // helperText={
            //   formValues.image.error &&
            //   formValues.image.errorMessage
            // }
            /> */}
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Phone Number"
              name="phone_number"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.phone_number}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Points "
              name="points"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.points}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Email "
              name="email"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.email}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Whatsapp link  "
              name="whatsapp_link"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.whatsapp_link}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Facebook link  "
              name="facebook_link"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.facebook_link}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Instgram link "
              name="instgram_link"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.instgram_link}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="password "
              name="password"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.password}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
          <Controls.Input
              placeholder="car image"
              className="form-control"
              label=""
              name="car_image"
              fullWidth
              required
              type="file"
              onChange={handlefile}
              error={errors.car_image}
            />
            {/* <TextField
              placeholder="car image"
              className="form-control"
              label="car image"
              name="car_image"
              variant="outlined"
              fullWidth
              type="file"
              // value={formValues.car_image.value}
              onChange={handlefile}
            // error={formValues.car_image.error}
            // helperText={
            //   formValues.car_image.error &&
            //   formValues.car_image.errorMessage
            // }
            /> */}
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Car model "
              name="car_model"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.car_model}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="car number "
              name="car_number"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.car_number}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.Input
              label="Vehicle year of manufacture "
              name="car_manufacturing_year"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              className="form-control"
              error={errors.car_manufacturing_year}
              type="amount"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <div class="select">
              <select
                className="form-control"
                name="country_code"
                class="select-text"
                required
                onChange={(e) => setlevels(e.target.value)}
              >
                <option selected disabled>
                  Chooes option
                </option>
                {level.map((opts, i) => (
                  <option key={i} value={opts.iso}>
                    {opts.iso}
                  </option>
                ))}
              </select>
              <span class="select-highlight"></span>
              <span class="select-bar"></span>
              <label class="select-label">country code</label>
            </div>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} spacing={2} class="div-btn-submit">
          <Controls.Button
            type="submit"
            text="submit"
            onClick={addForm}
            variant="contained"
            className="btn-submit"
          ></Controls.Button>
        </Grid>
      </Form>
    </div>
  );
}
