export const LOGIN = "/";
export const DASHBOARD = "/dashboard";
export const MARSHALLS = "/marshalls";
export const TRIPS = "/trips";
export const MY_TRIPS = "/my_trips";
export const USER = "/users";
export const LEVEL = "/level";
export const ANNOUNCEMENTS = "/announcements";
export const PROFILE = "/profile";
export const SITEINFO = "/Siteinfo";
export const ADDMARSALLS = "/addmarshalls";
export const JOINED_TRIPS = "/joinedtrips";
export const CREATED_TRIPS = "/createdtrips";
// export const LOGIN = "/login";
// export const DASHBOARD = "/dashboard";
// export const PAGE1 = "/page1";
// export const AUTH_PAGE1 = "/authorized1";
