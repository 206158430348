import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import Header from "../../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { getAllLevel } from "../../../services";
import * as sweetalert from 'sweetalert';
import axios from 'axios';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import {
  randomId,
} from '@mui/x-data-grid-generator';


const initialRows = [
  {
    id: 1,
    Levelname: "Jon Snow",
    Levelpoints: 44,
    LevelMarshallpoints: 35,
    
  },
  {
    id: 2,
    Levelname: "Jon Snow",
    Levelpoints: 44,
    LevelMarshallpoints: 35,
    
  },
  {
    id: 3,
    Levelname: "Jon Snow",
    Levelpoints: 44,
    LevelMarshallpoints: 35,
    
  },
  {
    id: 4,
    Levelname: "Jon Snow",
    Levelpoints: 44,
    LevelMarshallpoints: 35,
    
  },
  {
    id: 5,
    Levelname: "Jon Snow",
    Levelpoints: 44,
    LevelMarshallpoints: 35,
    
  },
];


function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, 
      Levelname: '',Levelpoints:'', LevelMarshallpoints:'',isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <>
    <GridToolbarContainer>
     
   
    </GridToolbarContainer>

     <GridToolbarContainer>
     <GridToolbarColumnsButton />
     <GridToolbarFilterButton />
     <GridToolbarDensitySelector />
     <GridToolbarExport />
   </GridToolbarContainer>
   </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Level = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState(initialRows);
  const [level, setlevel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getAllLevel()
      .then((res) => {
        console.log("Dashboard > getAllLevel > res=", res);
        setlevel(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setlevel([]);
        setIsLoading(false);
      });
    
      return () => {
        console.log("axios cleanup.");
      };
    }, []);
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
   
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setlevel(level.filter((row) => row.id !== id));
    console.log(level);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const [data1,setdata1] = useState();

  const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const data1=updatedRow.id;
         const data2 ={
            __method:'put',
            name:updatedRow.name,
            points:updatedRow.points,
            marshall_points:updatedRow.marshall_points,
            must_attend_count:updatedRow.must_attend_count,
        }
        let headers = {};
        if (localStorage.getItem("SavedToken") !== null) {
          headers = { Authorization: localStorage.getItem("SavedToken") };
        }
        // axios.get(`http://localhost:8000/sanctum/csrf-cookie`).then(response => {
          if(data2.name === ''){
            sweetalert('The Level name value field is required.')
          }
          else if(data2.points === ''){
            sweetalert('The Level Points  field is required.')
          }
          else if(data2.marshall_points === ''){
            sweetalert('The Level Marshall Points field is required.')
          }
          else if(data2.must_attend_count === ''){
            sweetalert('The Level Marshall attend count field is required.')
          }
          else{
            axios.put(`https://ijk.expentech.com/api/admin_panel/levels/${data1}`,data2,{headers:headers}).then(res=>{
              

               sweetalert('Update Successfully');
              });
              return updatedRow;
            } 
      
    }

  const columns = [
    // { field: "id", headerName: "ID", flex: 0.5 },

    {
      field: "name",
      headerName: "Level name",
      
      flex: 1,
      cellClassName: "name-column--cell",
      editable: true
    },
    {
      field: "points",
      headerName: "Level Points",
      type: "text",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: "marshall_points",
      headerName: "Level Marshall Points",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: "must_attend_count",
      headerName: "Count",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 300, minWidth: 150, maxWidth: 500 ,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
   
        ];
      },
    },
  ];

  return (
    <Box m="2px 5px 0px 5px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Header title="Levels" />
        </Box>
       
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[500],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.primary[700],
            borderBottom: "none",
            fontSize: "15px !important",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "15px !important",
            color: `${colors.grey[600]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.white[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
             <DataGridPro
               
          rows={level}
          columns={columns}
          editMode="row"
          pageSize={5}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
        
          components={{
            
            Toolbar: EditToolbar  
          }}
         
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
             
          }}
          experimentalFeatures={{ newEditingApi: true 
          }}
          />
      </Box>
    </Box>
  );
};

export default Level;
