import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../styles/theme";
import EditIcon from "@mui/icons-material/Edit";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../global/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Header from "../../../components/Header";
import Tooltip from '@mui/material/Tooltip';
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ImageIcon from "@mui/icons-material/Image";
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    console.log("e");
    // const id = randomId();
    // setRows((oldRows) => [
    //   ...oldRows,
    //   { id, name: "", email: "", image: "", phone: "", isNew: true },
    // ]);
    // setRowModesModel((oldModel) => ({
    //   ...oldModel,
    //   [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    // }));
  };

  return (
    <>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const Profile = () => {
  const [profile, setProfile] = useState([]);
  const [img, setImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [rows, setRows] = React.useState();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);

    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/user_profile`, {
        headers: headers,
      })
      .then((res) => {
        setProfile([res.data.data]);
        setImg(res.data.data.image);
        console.log(res.data.data.image);
        console.log(img);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("axios err=", err);
        setProfile([]);
        setIsLoading(false);
      });

    return () => {
      console.log("axios cleanup.");
    };
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    navigate(`/editProfile/${id}`, { state: { id: id } });
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {};
  const handleViewClick = (id) => () => {
    navigate(`/ViewPrfile/${id}`, { state: { id: id } });
  };
  const handleJoinedClick = (id) => () => {
    navigate(`/joinedtrips/${id}`, { state: { id: id } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const [open, setOpen] = React.useState(false);
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const handleClickOpen = (id) => () => {
    setOpen(true);
    let headers = {};
    if (localStorage.getItem("SavedToken") !== null) {
      headers = { Authorization: localStorage.getItem("SavedToken") };
    }
    axios
      .get(`https://ijk.expentech.com/api/admin_panel/user_profile`, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        setDataAnnouncement(res.data.data.image);
        console.log(dataAnnouncement);
        setIsLoading(false);
      });
  };

  const handleClose = () => {

    setOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "User ID",
      type: "text",
      // flex: 4,
      flex: 1,
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "username",
      headerName: "Full name",
      type: "number",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "text",
      // flex: 1,
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      minWidth: 150,
      maxWidth: 500,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={
              <Tooltip title="View">
                 <VisibilityRoundedIcon />
              </Tooltip>
           }
            label="View"
            onClick={handleViewClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
          icon={
            <Tooltip title="show Image">
              <ImageIcon />
            </Tooltip>
          }
          label="view"
          onClick={handleClickOpen(id)}
          color="inherit"
        />,
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Personal Image</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <img
                height="100"
                width="200"
                alt="med1"
                src={"https://ijk.expentech.com/storage/" + dataAnnouncement}
              />
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>,
        ];
      },
    },
  ];

  return (
    <div className="main-div">
      <Header title="My profile" />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box></Box>
        </Box>

        <Box
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.redAccent[500],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[700],
              borderBottom: "none",
              fontSize: "15px !important",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "15px !important",
              color: `${colors.grey[600]} !important`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.white[100],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGridPro
            rows={profile}
            columns={columns}
            editMode="row"
            pageSize={5}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Box>
    </div>
  );
};
export default Profile;
