/* eslint-disable no-undef */

import React from 'react'
import {useState} from 'react'
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core'
import { AccountCircle, LockRounded } from '@material-ui/icons';
import axios from 'axios';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as sweetalert from "sweetalert";

const Login = ({ username }) => {
     
    const navigate = useNavigate();
    const [loginInput,setLogin] = useState({
        username:'',
        password:'',
        fcm_token:'',
        error_list:[]
    });
    const handeleInput =(e)=>{
        e.persist();
        setLogin({...loginInput,[e.target.name]: e.target.value});
    }
    const loginSubmit =(e) =>{
        e.preventDefault();

        const data ={
            username:loginInput.username,
            password:loginInput.password,
            fcm_token:4,
        }
        
        console.log(data);
            axios.post(`https://ijk.expentech.com/api/login`,data).then(
                (res)=>
                {
                console.log('uy8u8');
                console.log(res.status );

                if(res.status === 200){
                    let token=[]
                    let email=[]
                    let username=[]
                    let userid=[]
                    console.log(res.data);
                    token.push(res.data.data.token);
                    email.push(res.data.data.user.email);
                    username.push(res.data.data.user.username);
                    userid.push(res.data.data.user.id);
                    
                    // console.log(item)
                    localStorage.setItem("SavedToken", 'Bearer ' + token);
                    localStorage.setItem("email", email);
                    localStorage.setItem("username", username);
                    localStorage.setItem("userid", userid);
                    axios.defaults.headers.common['Authorization'] = 'Bearer' + token;
                    sweetalert('success');
                    navigate('/dashboard');
            }

            else{
                sweetalert('error');
                setLogin({...loginInput,error_list: res.error});
            }
            
        });
     
    }
    return (
        <div>
            <form className="form" onSubmit={loginSubmit} style={{height:'100vh'}}>
            <Grid container className='h-100vh' >
                <Grid item xs={12} sm={6} style={{height:'100%'}}>
                   
                    <img src='login.jpg' alt="this page for login" style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'relative' }} />
                    {/* <div style={{position: "absolute", color: "white",top: 10,left: "50%",transform: "translateX(-50%)",backgroundColor:'black'}}></div> */}
                </Grid>
                <Grid container item alignItems='center'
                    direction='column'
                    justify="space-between"
                    xs={12} sm={6}
                    style={{ padding: 10 }}>
                    <div />
                    <div style={{ dispaly: "flex", flexDirection: "column", maxwidth: 400, minwidth: 300 }}>
                        <Grid container justifyContent='center'>
                            <img src="logo.PNG" alt='logo' width={200} />
                        </Grid>

                    <div style={{ dispaly: "flex", flexDirection: "row", maxwidth: 400, minwidth: 300  , marginTop:50, marginBottom:50 }}>
                       <div>

                        <TextField label='EmailorUsername' margin='normal' name='username' onChange={handeleInput} value={loginInput.username}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'><AccountCircle /></InputAdornment>

                                )
                            }} />
                        <Typography  variant='span'>{loginInput.error_list.username}</Typography>
                       </div>
                       </div>
                       <div>
                        <TextField label='Password' margin='normal' type='password' name='password'  onChange={handeleInput} value={loginInput.password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'><LockRounded /></InputAdornment>

                                )
                            }} />
                        <Typography  variant='span'>{loginInput.error_list.password}</Typography>

                    
                         </div>
                        <div style={{ height: 20 }} />


                        <Button
                            type="submit"
                            style={{ backgroundColor: "red", fontSize: "18px",color:'#fff' }}
                            variant="contained">
                            Log in
                        </Button>

                        <div style={{ height: 20 }} />

                        {/* <Button>Don't Have an account?
                            <Button
                                style={{ color: 'red', padding: 0 }}>Sign up</Button>
                        </Button> */}

                    </div>



                    <div />
                </Grid>

            </Grid>
            </form>
        </div>
    )
}

export default Login
